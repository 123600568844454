import { Role } from "@/acl/roles";
import { ALPHAX_PRODUCTS_DETAILS } from "@/assets/constants/products/alphax";

export default {
  orgName: "AlphaX",
  logoPath: require("@/assets/images/logo/alphax.png"),
  allowedLoginMethods: ["Google"],
  hideCopyrightFooter: true,
  products: ALPHAX_PRODUCTS_DETAILS,
  hasHoldingData: true,
  help: {
    knowledgeBase: true,
    contact: false,
  },
  possibleRoles: [Role.superAdmin, Role.admin, Role.viewer],
  admin: true,
  availableLanguages: ["en"],
};
