import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import {
  PerformanceStatistic,
  STANDARD_PERF_STATS_WITH_BENCHMARK,
  STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
} from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS, ZoomButton } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const msmIgDynamicBondOpportunities = {
  productId: "msm-ig-dynamic-bond-opportunities",
  storeModule: "msmIgDynamicBondOpportunities",
  productName: "MSM IG Dynamic Bond Opportunities",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.trackingError,
      PerformanceStatistic.informationRatio,
      PerformanceStatistic.alpha,
      PerformanceStatistic.beta,
    ],
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const msmBiotechDynamicPortfolio = {
  productId: "msm-biotech-dynamic-portfolio",
  storeModule: "msmBiotechDynamicPortfolio",
  productName: "MSM Biotech Dynamic Portfolio",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const slgOpportunitiesFund = {
  productId: "slg-opportunities-fund",
  storeModule: "slgOpportunitiesFund",
  productName: "SLG Opportunities Fund - Monthly Liquidity",
  productShortName: "SLG Opportunities Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "slg-opportunities",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.monthly,
    zoomButtons: [
      ZoomButton.threeMonths,
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
  useCase: {
    componentPath: "msm/slgOpportunitiesFund/UseCase.vue",
  },
};

const slgOpportunitiesFundDaily = cloneDeep(slgOpportunitiesFund);
slgOpportunitiesFundDaily.productId = "slg-opportunities-fund-daily";
slgOpportunitiesFundDaily.storeModule = "slgOpportunitiesFundDaily";
slgOpportunitiesFundDaily.productName = "SLG Opportunities Fund - Daily Liquidity";
slgOpportunitiesFundDaily.isDefaultShareClass = false;
slgOpportunitiesFundDaily.allowPriceDataModification = false;
slgOpportunitiesFundDaily.priceModificationUpdateGcpStorage = false;
// We redirect to the main share class because the use case should always be using the main one.
slgOpportunitiesFundDaily.useCase.redirect = "/products/slg-opportunities-fund/use-case";
slgOpportunitiesFundDaily.live.updateFrequency = UpdateFrequency.daily;

const msmGlobalDefense = {
  productId: "msm-global-defense",
  storeModule: "msmGlobalDefense",
  productName: "MSM Global Defense",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const msmAlphaOpportunities = {
  productId: "msm-alpha-opportunities",
  storeModule: "msmAlphaOpportunities",
  productName: "MSM Alpha Opportunities",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const MSM_PRODUCTS_DETAILS = [
  slgOpportunitiesFund,
  slgOpportunitiesFundDaily,
  msmBiotechDynamicPortfolio,
  msmAlphaOpportunities,
  msmGlobalDefense,
  msmIgDynamicBondOpportunities,
];

export const MSM_PRODUCTS = MSM_PRODUCTS_DETAILS.map((details) => details.storeModule);
