import { cloneDeep } from "lodash";

import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

const CGE_TECH_CARE_PERF_STATS = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.sharpeRatio,
  PerformanceStatistic.sortinoRatio,
  PerformanceStatistic.alpha,
  PerformanceStatistic.beta,
  PerformanceStatistic.informationRatio,
  PerformanceStatistic.trackingError,
  PerformanceStatistic.ratioOfPositiveMonthlyReturns,
  PerformanceStatistic.ratioOfNegativeMonthlyReturns,
];

// ******---------- Cité Gestion Tech Care Fund ----------*****
const citeGestionTechCareFundRaUsd = {
  productId: "cite-gestion-tech-care-fund-ra-usd",
  storeModule: "citeGestionTechCareFundRaUsd",
  productName: "Cité Gestion Tech Care Fund RA USD",
  productShortName: "Cité Gestion Tech Care Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "cite-gestion-tech-care-fund",
  isDefaultShareClass: true,
  oldDataEndDate: "2023-11-20",
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: CGE_TECH_CARE_PERF_STATS,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    needLoginToSee: false,
  },
};

const citeGestionTechCareFundIaUsd = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundIaUsd.productId = "cite-gestion-tech-care-fund-ia-usd";
citeGestionTechCareFundIaUsd.storeModule = "citeGestionTechCareFundIaUsd";
citeGestionTechCareFundIaUsd.productName = "Cité Gestion Tech Care Fund IA USD";
citeGestionTechCareFundIaUsd.isDefaultShareClass = false;

const citeGestionTechCareFundRbEur = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundRbEur.productId = "cite-gestion-tech-care-fund-rb-eur";
citeGestionTechCareFundRbEur.storeModule = "citeGestionTechCareFundRbEur";
citeGestionTechCareFundRbEur.productName = "Cité Gestion Tech Care Fund RB EUR";
citeGestionTechCareFundRbEur.isDefaultShareClass = false;

const citeGestionTechCareFundRcChf = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundRcChf.productId = "cite-gestion-tech-care-fund-rc-chf";
citeGestionTechCareFundRcChf.storeModule = "citeGestionTechCareFundRcChf";
citeGestionTechCareFundRcChf.productName = "Cité Gestion Tech Care Fund RC CHF";
citeGestionTechCareFundRcChf.isDefaultShareClass = false;

const citeGestionTechCareFundSaUsd = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundSaUsd.productId = "cite-gestion-tech-care-fund-sa-usd";
citeGestionTechCareFundSaUsd.storeModule = "citeGestionTechCareFundSaUsd";
citeGestionTechCareFundSaUsd.productName = "Cité Gestion Tech Care Fund SA USD";
citeGestionTechCareFundSaUsd.isDefaultShareClass = false;

const citeGestionTechCareFundSbEur = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundSbEur.productId = "cite-gestion-tech-care-fund-sb-eur";
citeGestionTechCareFundSbEur.storeModule = "citeGestionTechCareFundSbEur";
citeGestionTechCareFundSbEur.productName = "Cité Gestion Tech Care Fund SB EUR";
citeGestionTechCareFundSbEur.isDefaultShareClass = false;

const citeGestionTechCareFundScChf = cloneDeep(citeGestionTechCareFundRaUsd);
citeGestionTechCareFundScChf.productId = "cite-gestion-tech-care-fund-sc-chf";
citeGestionTechCareFundScChf.storeModule = "citeGestionTechCareFundScChf";
citeGestionTechCareFundScChf.productName = "Cité Gestion Tech Care Fund SC CHF";
citeGestionTechCareFundScChf.isDefaultShareClass = false;

export const CGE_TECH_CARE_PRODUCTS_DETAILS = [
  citeGestionTechCareFundRaUsd,
  citeGestionTechCareFundIaUsd,
  citeGestionTechCareFundRbEur,
  citeGestionTechCareFundRcChf,
  citeGestionTechCareFundSaUsd,
  citeGestionTechCareFundSbEur,
  citeGestionTechCareFundScChf,
];

export const CGE_TECH_CARE_PRODUCTS = CGE_TECH_CARE_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
