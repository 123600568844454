import { GenericProductCards } from "@/assets/constants/analysisCards";
import { EventDate, FALGOM_EVENTS, GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { ZoomButton } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

const taroDiversified = {
  productId: "taro-diversified",
  storeModule: "taroDiversified",
  productName: "TARO® Diversified",
  productColor: ORG_COLOR,
  oldDataEndDate: "2022-01-31",
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: FALGOM_EVENTS.concat(EventDate.falgomTaroDiversifiedInception),
  },
  useCase: {
    componentPath: "falgom/taroDiversified/UseCase.vue",
  },
};

const arpUsEquities = {
  productId: "arp-us-equities",
  storeModule: "arpUsEquities",
  productName: "ARP US Equities",
  productColor: ORG_COLOR,
  oldDataEndDate: "2024-09-23",
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
    ],
    events: FALGOM_EVENTS.concat(EventDate.falgomArpUsEquitiesInception),
  },
};

const arpCommodities = {
  productId: "arp-commodities",
  storeModule: "arpCommodities",
  productName: "ARP Commodities",
  productColor: ORG_COLOR,
  oldDataEndDate: "2024-12-11",
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
    ],
    events: FALGOM_EVENTS.concat(EventDate.falgomArpCommoditiesInception),
  },
};

const liquidAlpha = {
  productId: "liquid-alpha",
  storeModule: "liquidAlpha",
  productName: "Liquid Alpha",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const globalTrading = {
  productId: "global-trading",
  storeModule: "globalTrading",
  productName: "Global Trading",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const equityArbitrage = {
  productId: "equity-arbitrage",
  storeModule: "equityArbitrage",
  productName: "Equity Arbitrage",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const globalCommodity = {
  productId: "global-commodity",
  storeModule: "globalCommodity",
  productName: "Global Commodity",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const digitalAsset = {
  productId: "digital-asset",
  storeModule: "digitalAsset",
  productName: "Digital Asset",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const digitalAlpha = {
  productId: "digital-alpha",
  storeModule: "digitalAlpha",
  productName: "Digital Alpha",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

const greaterChina = {
  productId: "greater-china",
  storeModule: "greaterChina",
  productName: "Greater China",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: [
      ZoomButton.sixMonths,
      ZoomButton.ytd,
      ZoomButton.threeYears,
      ZoomButton.fiveYears,
      ZoomButton.fullPeriod,
    ],
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.outperformance,
      GenericProductCards.drawdowns,
      GenericProductCards.returnProfile,
    ],
    perfStats: [
      PerformanceStatistic.cumulativeReturn,
      PerformanceStatistic.annualizedReturn,
      PerformanceStatistic.annualizedVolatility,
      PerformanceStatistic.maximumDrawdown,
      PerformanceStatistic.sharpeRatio,
      PerformanceStatistic.skewness,
      PerformanceStatistic.kurtosis,
      PerformanceStatistic.sortinoRatio,
      PerformanceStatistic.calmarRatio,
      PerformanceStatistic.omegaRatio,
    ],
    events: GLOBAL_EVENTS,
  },
};

export const FALGOM_PRODUCTS_DETAILS = [
  taroDiversified,
  arpUsEquities,
  arpCommodities,
  liquidAlpha,
  globalTrading,
  equityArbitrage,
  globalCommodity,
  digitalAsset,
  digitalAlpha,
  greaterChina,
];

export const FALGOM_PRODUCTS = FALGOM_PRODUCTS_DETAILS.map((details) => details.storeModule);
