import { Role } from "@/acl/roles";
import { K1P_PRODUCTS_DETAILS } from "@/assets/constants/products/k1p";

export default {
  orgName: "K1P",
  logoPath: require("@/assets/images/logo/k1p.jpg"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  products: K1P_PRODUCTS_DETAILS,
  hasHoldingData: true,
  possibleRoles: [Role.superAdmin, Role.admin, Role.viewer],
  allowedRoles: [Role.superAdmin, Role.admin, Role.viewer],
  admin: true,
  availableLanguages: ["en", "fr"],
};
