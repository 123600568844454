import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- GOLETA SELECT FUND ----------*****
const goletaSelectFundShareClassB = {
  productId: "goleta-select-fund-share-class-b",
  storeModule: "goletaSelectFundShareClassB",
  productName: "Goleta Select Fund Share Class B",
  productShortName: "Goleta Select Fund",
  productColor: ORG_COLOR,
  productShareClassRoot: "goleta-select-fund",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const goletaSelectFundShareClassF = cloneDeep(goletaSelectFundShareClassB);
goletaSelectFundShareClassF.productId = "goleta-select-fund-share-class-f";
goletaSelectFundShareClassF.storeModule = "goletaSelectFundShareClassF";
goletaSelectFundShareClassF.productName = "Goleta Select Fund Share Class F";
goletaSelectFundShareClassF.isDefaultShareClass = false;

export const GOLETA_PRODUCTS_DETAILS = [goletaSelectFundShareClassB, goletaSelectFundShareClassF];

export const GOLETA_PRODUCTS = GOLETA_PRODUCTS_DETAILS.map((details) => details.storeModule);
