import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITH_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

const equinoxAktienEuroland = {
  productId: "equinox-aktien-euroland",
  storeModule: "equinoxAktienEuroland",
  productName: "EQUINOX Aktien Euroland",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxAktienWeltChf = {
  productId: "equinox-aktien-welt-chf",
  storeModule: "equinoxAktienWeltChf",
  productName: "EQUINOX Aktien Welt CHF",
  productShortName: "EQUINOX Aktien Welt",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxAktienSchweizChfI = {
  productId: "equinox-aktien-schweiz-chf-i",
  storeModule: "equinoxAktienSchweizChfI",
  productName: "EQUINOX Aktien Schweiz CHF I",
  productShortName: "EQUINOX Aktien Schweiz",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-aktien-schweiz",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxAktienSchweizChfR = {
  productId: "equinox-aktien-schweiz-chf-r",
  storeModule: "equinoxAktienSchweizChfR",
  productName: "EQUINOX Aktien Schweiz CHF R",
  productShortName: "EQUINOX Aktien Schweiz",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-aktien-schweiz",
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxMixedChfIBvg = {
  productId: "equinox-mixed-chf-i-bvg",
  storeModule: "equinoxMixedChfIBvg",
  productName: "EQUINOX Mixed CHF I BVG",
  productShortName: "EQUINOX Mixed",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-mixed",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxMixedChfH = {
  productId: "equinox-mixed-chf-h",
  storeModule: "equinoxMixedChfH",
  productName: "EQUINOX Mixed CHF h",
  productShortName: "EQUINOX Mixed",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-mixed",
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxMixedChfIH = {
  productId: "equinox-mixed-chf-i-h",
  storeModule: "equinoxMixedChfIH",
  productName: "EQUINOX Mixed CHF I h",
  productShortName: "EQUINOX Mixed",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-mixed",
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxAktienSchweizFlexChfI = {
  productId: "equinox-aktien-schweiz-flex-chf-i",
  storeModule: "equinoxAktienSchweizFlexChfI",
  productName: "EQUINOX Aktien Schweiz Flex CHF I",
  productShortName: "EQUINOX Aktien Schweiz Flex",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxObligationenGlobalEur = {
  productId: "equinox-obligationen-global-eur",
  storeModule: "equinoxObligationenGlobalEur",
  productName: "EQUINOX Obligationen Global EUR",
  productShortName: "EQUINOX Obligationen Global",
  productColor: ORG_COLOR,
  productShareClassRoot: "equinox-obligationen-global",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: STANDARD_PERF_STATS_WITH_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const equinoxObligationenGlobalChf = _.cloneDeep(equinoxObligationenGlobalEur);
equinoxObligationenGlobalChf.productId = "equinox-obligationen-global-chf";
equinoxObligationenGlobalChf.storeModule = "equinoxObligationenGlobalChf";
equinoxObligationenGlobalChf.productName = "EQUINOX Obligationen Global CHF";
equinoxObligationenGlobalChf.isDefaultShareClass = false;

export const EQUINOX_PRODUCTS_DETAILS = [
  equinoxAktienEuroland,
  equinoxAktienWeltChf,
  equinoxAktienSchweizChfI,
  equinoxAktienSchweizChfR,
  equinoxMixedChfIBvg,
  equinoxMixedChfH,
  equinoxMixedChfIH,
  equinoxAktienSchweizFlexChfI,
  equinoxObligationenGlobalEur,
  equinoxObligationenGlobalChf,
];

export const EQUINOX_PRODUCTS = EQUINOX_PRODUCTS_DETAILS.map((details) => details.storeModule);
