import { Role } from "@/acl/roles";
import {
  ALQUANT_INTERNAL_ALLOWED_ROLES,
  ALQUANT_PRODUCTS_DETAILS,
  ALQUANT_PRODUCTS_DEV_DETAILS,
} from "@/assets/constants/products/alquant";
import { i18n } from "@/main.js";

export default {
  orgName: "Alquant",
  showHomePageHeader: true,
  homePageLogoPath: require("@/assets/images/logo/alquant.svg"),
  logoPath: require("@/assets/images/logo/alquant-colored.svg"),
  navBarLogoStyle: "width: 100%; height: auto; max-height: 44px",
  allowedLoginMethods: ["Google", "Microsoft"],
  background: {
    path: require("@/assets/images/background/alquant.svg"),
  },
  slogan: true,
  hasHoldingData: true,
  products: ALQUANT_PRODUCTS_DETAILS,
  help: {
    knowledgeBase: true,
    faq: {
      // A function here to be able to use i18n. Otherwise, this
      // file is called and the data initialized before i18n is
      // ready.
      faqs: () => [
        {
          id: 1,
          question: i18n.t("wlps.alquant.faq.whatAlquant.question"),
          ans: i18n.t("wlps.alquant.faq.whatAlquant.answer"),
        },
        {
          id: 2,
          question: i18n.t("wlps.alquant.faq.whatGoal.question"),
          ans: i18n.t("wlps.alquant.faq.whatGoal.answer"),
        },
        {
          id: 3,
          question: i18n.t("wlps.alquant.faq.howToInvest.question"),
          ans: i18n.t("wlps.alquant.faq.howToInvest.answer"),
        },
        {
          id: 999,
          question: i18n.t("wlps.alquant.faq.moreQuestions.question"),
          ans: i18n.t("wlps.alquant.faq.moreQuestions.answer"),
        },
      ],
    },
    contact: true,
  },
  possibleRoles: [
    Role.superAdmin,
    Role.admin,
    Role.employee,
    Role.alvolaCHF,
    Role.lifeSciencesToolsAndServices,
  ],
  availableLanguages: ["en"],
  followFeatureActivated: true,
  customSections: [
    {
      getTitle: () => "Products (dev)",
      meta: {
        authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
      },
      subSections: ALQUANT_PRODUCTS_DEV_DETAILS.map((productConfig) => ({
        navMenuItem: {
          getName: () => productConfig.productShortName ?? productConfig.productName,
          slug: productConfig.productId,
          meta: {
            authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
          },
          submenu: [
            {
              url: `/products-dev/${productConfig.productId}/overview`,
              name: "Overview",
              slug: `${productConfig.productId}-overview`,
              icon: "EyeIcon",
            },
            {
              url: `/products-dev/${productConfig.productId}/live-analysis`,
              name: "Analysis",
              slug: `${productConfig.productId}-live-analysis`,
              icon: "BarChart2Icon",
            },
            ...(productConfig.useCase
              ? [
                  {
                    url: `/products-dev/${productConfig.productId}/use-case`,
                    name: "Use case",
                    slug: `${productConfig.productId}-use-case`,
                    icon: "FileTextIcon",
                  },
                ]
              : []),
            {
              url: `/products-dev/${productConfig.productId}/documents`,
              name: "Documents",
              slug: `${productConfig.productId}-documents`,
              icon: "DownloadIcon",
            },
          ],
        },
        router: [
          {
            path: `/products-dev/${productConfig.productId}/overview`,
            name: `products_dev_${productConfig.storeModule}_overview`,
            component: () => import("../views/products/DefaultOverview.vue"),
            meta: {
              productId: productConfig.productId,
              authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
            },
            props: {
              productId: productConfig.productId,
              lockedBehindLogin: productConfig.lockedBehindLogin,
              storeModule: productConfig.storeModule,
              productName: productConfig.productName,
              productShareClassRoot: productConfig.productShareClassRoot,
              mode: productConfig.live?.mode,
              updateFrequency: productConfig.live?.updateFrequency,
              showNextSubscriptionAndRedemption: productConfig.showNextSubscriptionAndRedemption,
              timelineData: productConfig.timelineData,
            },
          },
          {
            path: `/products-dev/${productConfig.productId}/live-analysis`,
            name: `products_dev_${productConfig.storeModule}_live_analysis`,
            component: () => import("../views/products/DefaultAnalysis.vue"),
            meta: {
              productId: productConfig.productId,
              authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
            },
            props: {
              productId: productConfig.productId,
              lockedBehindLogin: productConfig.lockedBehindLogin,
              storeModule: productConfig.storeModule,
              productName: productConfig.productName,
              productShareClassRoot: productConfig.productShareClassRoot,
              genericCardsToShow: productConfig.live.genericCardsToShow,
              perfStats: productConfig.live.perfStats,
              productColor: productConfig.productColor,
              allowLiveComparison: productConfig.live.allowLiveComparison,
              events: productConfig.live.events,
              allowLogScale: productConfig.live.allowLogScale,
              defaultLogScale: productConfig.live.defaultLogScale,
              mode: productConfig.live?.mode,
              tabmenuItems: productConfig.tabmenuItems,
              tabmenuText: productConfig.live.tabmenuText,
            },
          },
          ...(productConfig.useCase
            ? [
                {
                  path: `/products-dev/${productConfig.productId}/use-case`,
                  name: `products_dev_${productConfig.storeModule}_use_case`,
                  component: () =>
                    import(`../views/products/${productConfig.useCase.componentPath}`),
                  meta: {
                    productId: productConfig.productId,
                    authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
                  },
                  props: {
                    storeModule: productConfig.storeModule,
                  },
                },
              ]
            : []),
          {
            path: `/products-dev/${productConfig.productId}/documents`,
            name: `products_dev_${productConfig.storeModule}_documents`,
            component: () => import("../views/products/DefaultDocuments.vue"),
            meta: {
              productId: productConfig.productId,
              authorize: ALQUANT_INTERNAL_ALLOWED_ROLES,
            },
            props: {
              productId: productConfig.productId,
              lockedBehindLogin: productConfig.lockedBehindLogin,
              storeModule: productConfig.storeModule,
              productName: productConfig.productName,
              productShareClassRoot: productConfig.productShareClassRoot,
            },
          },
        ],
      })),
    },
  ],
};
