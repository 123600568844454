import { Role } from "@/acl/roles";
import { GOLETA_PRODUCTS_DETAILS } from "@/assets/constants/products/goleta";

export default {
  orgName: "Goleta",
  showHomePageHeader: true,
  logoPath: require("@/assets/images/logo/goleta.jpg"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  hideLogoHomePage: true,
  background: {
    path: require("@/assets/images/background/goleta.jpg"),
  },
  products: GOLETA_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en", "fr", "de"],
};
