import components from "./components.js";
import pages from "./pages.js";
import wlps from "./wlps.js";

export default {
  apply: "Aplicar",
  asOf: (ctx) => `a partir de ${ctx.named("date")}`,
  cancel: "Cancelar",
  contactUs: "Contate-nos",
  edit: "Editar",
  error: "Erro",
  from: "De",
  ok: "Ok",
  loading: "Carregando...",
  logIn: "Entrar",
  logOut: "Sair",
  no: "Não",
  others: "Outros",
  register: "Registrar",
  save: "Salvar",
  saving: "Salvando...",
  serverError:
    "Ocorreu um erro em nosso servidor. Um relatório foi enviado à nossa equipe e corrigiremos o mais rápido possível. Não hesite em nos contatar se o problema persistir.",
  success: "Sucesso",
  to: "Para",
  warning: "Aviso",
  yes: "Sim",
  investorProfileTypes: {
    institutional: "Investidor institucional",
    professional: "Investidor profissional",
    retail: "Investidor de varejo",
  },
  layouts: {
    main: {
      dev: "Desenvolvimento",
      devTooltip: "Este produto está em desenvolvimento",
      admin: {
        listUsers: "Lista de usuários",
        platformSettings: "Configurações da plataforma",
        products: "Produtos",
        sectionName: "Admin",
        operations: {
          name: "Operações",
          bulkPriceDataUpdate: {
            name: "Atualizar dados de preços",
          },
          generateFactsheets: {
            name: "Gerar fichas técnicas",
          },
          bulkComponentsUpdate: {
            name: "Importar componentes genéricos",
          },
          exports: {
            name: "Exportações",
          },
          overrideHoldingData: {
            name: "Substituir dados de posição",
          },
        },
      },
      help: {
        contact: "Contato",
        faq: "FAQ",
        knowledgeBase: "Base de conhecimento",
        sectionName: "Ajuda",
      },
      products: {
        analysis: "Análise",
        documents: "Documentos",
        factsheet: "Ficha técnica",
        overview: "Visão geral",
        sectionName: "Produtos",
        useCase: "Caso de uso",
      },
    },
    fullPage: {
      backHome: "Voltar para a página inicial",
    },
    profileDropDown: {
      settings: "Configurações",
      dialog: {
        adminAsRegularOnMobile: {
          text: "Os recursos administrativos não podem ser acessados em dispositivos móveis. Para experimentar completamente a plataforma, utilize um computador desktop. Os recursos administrativos desativados incluem (mas não estão limitados a): edição de elementos na Visão Geral, edição da Ficha Técnica, upload ou exclusão de documentos e acesso à seção administrativa.",
          title: "Computador desktop necessário para recursos administrativos",
        },
      },
    },
    theFooter: {
      allRightReserved: "Todos os direitos reservados",
      copyright: "Copyright",
      ombudsman: "Ombudsman",
      privacyPolicy: "Política de privacidade",
      termsOfUse: "Termos de uso",
    },
    verticalNavMenu: {
      errorLoading: "Erro ao carregar o menu",
    },
    verticalNavMenuGroup: {
      search: "Pesquisar",
      showMore: "Mostrar mais",
    },
  },
  perfStats: {
    alpha: "Alpha",
    annualizedReturn: "Retorno anualizado",
    annualizedVolatility: "Volatilidade anualizada",
    averageMonthlyReturns: "Retorno médio mensal",
    averageNegativeMonthlyReturns: "Retorno médio negativo mensal",
    averagePositiveMonthlyReturns: "Retorno médio positivo mensal",
    beta: "Beta",
    calmarRatio: "Índice de Calmar",
    correlation: "Correlação",
    cumulativeReturn: "Retorno acumulado",
    informationRatio: "Índice de Informação",
    kurtosis: "Curtose",
    m2RiskAdjustedPerfMonthly: "Medida M2",
    maxConsecutiveNegativeMonthlyReturns: "Máximo de meses consecutivos de retornos negativos",
    maxConsecutivePositiveMonthlyReturns: "Máximo de meses consecutivos de retornos positivos",
    maxNegativeMonthlyReturn: "Maior retorno negativo mensal",
    maxPositiveMonthlyReturn: "Maior retorno positivo mensal",
    maximumDrawdown: "Maior perda acumulada",
    omegaRatio: "Índice Omega",
    ratioOfNegativeMonthlyReturns: "Proporção de retornos mensais negativos",
    ratioOfPositiveMonthlyReturns: "Proporção de retornos mensais positivos",
    returnToVolatilityRatio: "Índice de retorno à volatilidade",
    sharpeRatio: "Índice Sharpe",
    skewness: "Assimetria",
    sortinoRatio: "Índice Sortino",
    trackingError: "Erro de acompanhamento",
    treynorRatio: "Índice Treynor",
    valueAtRiskOnePercentOneMonth: "Valor em Risco (VaR) 1% em 1 mês",
    valueAtRiskFivePercentOneMonth: "Valor em Risco (VaR) 5% em 1 mês",
  },
  validations: {
    afterDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `A data deve ser posterior a ${ctx.named("minDate")} (inclusa)`
        : `A data deve ser pelo menos ${ctx.named("nDaysApart")} dias após ${ctx.named("minDate")}`,
    beforeDate: (ctx) =>
      ctx.named("nDaysApart") == 0
        ? `A data deve ser anterior a ${ctx.named("maxDate")} (inclusa)`
        : `A data deve ser pelo menos ${ctx.named("nDaysApart")} dias antes de ${ctx.named("maxDate")}`,
    confirmed: "O campo não corresponde ao anterior.",
    dateFormat: "A data deve estar no formato AAAA-MM-DD.",
    email: "O e-mail não é válido.",
    maxFileSize: (ctx) =>
      `O arquivo é maior que o tamanho máximo autorizado (${(
        ctx.named("maxSizeBytes") /
        1000 /
        1000
      ).toFixed(0)} MB).`,
    maxChar: (ctx) => `O campo não pode ter mais que ${ctx.named("length")} caracteres.`,
    minChar: (ctx) => `O campo precisa de pelo menos ${ctx.named("length")} caracteres.`,
    minValue: (ctx) => `O valor deve ser maior ou igual a ${ctx.named("min")}.`,
    phoneNumber: "Este campo não é um número de telefone válido.",
    required: "Este campo é obrigatório.",
    roleFormat: 'O nome da função não pode conter o caractere ":".',
    sum: (ctx) =>
      `A soma esperada é ${ctx.named("expectedValue")} (atual: ${ctx.named("currentValue")}).`,
  },
  wlps,
  pages,
  components,
};
