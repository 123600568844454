import { Role } from "@/acl/roles";
import { GLOBAL_GATE_PRODUCTS_DETAILS } from "@/assets/constants/products/global-gate";

export default {
  orgName: "Global Gate AM",
  logoPath: require("@/assets/images/logo/global-gate.svg"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  products: GLOBAL_GATE_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
  followFeatureActivated: true,
  hasUpdatablePriceData: true,
  hasHoldingData: true,
  colorScheme: [
    "#1D1C33",
    "#001B6C",
    "#97A7C8",
    "#E0B990",
    "#BEC3D8",
    "#D7D2CB",
    "#71BEE4",
    "#E4A997",
    "#037655",
    "#AD1AAC",
  ],
};
