import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- K2 ----------*****
const k2ClassM = {
  productId: "k2-class-m",
  storeModule: "k2ClassM",
  productName: "K2 Managed Account",
  productShortName: "K2",
  productColor: ORG_COLOR,
  productShareClassRoot: "k2",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const k2ClassI = cloneDeep(k2ClassM);
k2ClassI.productId = "k2-class-i";
k2ClassI.storeModule = "k2ClassI";
k2ClassI.productName = "K2 Class I";
k2ClassI.isDefaultShareClass = false;

// ******---------- AMC ----------*****
const amcSuisseSecuredUcitsIv = {
  productId: "amc-suisse-secured-ucits-iv",
  storeModule: "amcSuisseSecuredUcitsIv",
  productName: "K2 AMC Secured UCITS IV",
  productShortName: "AMC",
  productColor: ORG_COLOR,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const K1P_PRODUCTS_DETAILS = [k2ClassM, k2ClassI, amcSuisseSecuredUcitsIv];

export const K1P_PRODUCTS = K1P_PRODUCTS_DETAILS.map((details) => details.storeModule);
