export default {
  auth: {
    resetPasswordLink: {
      forgotPassword: "Esqueceu sua senha?",
    },
  },
  cards: {
    needLoginCard: {
      needLogged: "Você precisa estar logado para ver este conteúdo.",
    },
  },
  compliance: {
    cookies: {
      buttonText: "Aceitar",
      cookiesUsage:
        "Este site utiliza Cookies para melhorar a usabilidade. Ao utilizar este site, você declara seu consentimento para o uso de Cookies, bem como com a política de privacidade onde você pode encontrar mais informações sobre Cookies. Link: {0}.",
      privacyPolicy: "Política de privacidade",
    },
  },
  components: {
    details: {
      factsheet: {
        aumFormat: (ctx) =>
          `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(
            1
          )} ${ctx.named("aum") > 1000000 ? "milhão" : "milhões"}${
            ctx.named("asOfDate") ? ` (em ${ctx.named("asOfDate")})` : ""
          }`,
        lastPrice: (ctx) => `${ctx.named("currency")} ${ctx.named("lastPrice").toFixed(2)}`,
      },
      overview: {
        aumFormat: (ctx) =>
          `${ctx.named("currency")} ${(ctx.named("aum") / 1000000).toFixed(1)} ${ctx.named("aum") > 1000000 ? "milhão" : "milhões"} (em ${ctx.named("asOfDate")})`,
        lastPrice: (ctx) =>
          `${ctx.named("currency")} ${ctx.named("lastPrice").toFixed(2)} (em ${ctx.named("asOfDate")})`,
      },
    },
    download: {
      tooltip: "Baixar como imagem",
      prompt: {
        downloadPngButton: "Baixar como PNG",
        downloadSvgButton: "Baixar como SVG",
        text: "Escolha o formato em que deseja baixar o componente.",
        title: "Baixar componente",
      },
    },
  },
  datePickers: {
    datePicker: {
      selectDateTooltip: "Selecionar data",
    },
    dateRangePicker: {
      from: "De (aaaa-mm-dd)",
      fullPeriod: "Período completo",
      to: "Até (aaaa-mm-dd)",
    },
    zoomPicker: {
      fiveYears: "5A",
      fullPeriod: "MAX",
      oneMonth: "1M",
      oneYear: "1A",
      sixMonths: "6M",
      threeMonths: "3M",
      tenYears: "10A",
      threeYears: "3A",
      twoYears: "2A",
      ytd: "YTD",
    },
  },
  dropdowns: {
    iconsDropdown: {
      noIcon: "Sem ícone",
    },
  },
  editPrompt: {
    copyInAllLangs: "Copiar em todos os idiomas",
    editField: "Editar campo",
    enterText: "Insira um texto",
    overwriteDataAllShareClasses: "Substituir dados de todas as classes de ações",
    id: "ID",
    idTooltip:
      "Este identificador único serve como referência para o componente dentro da plataforma. Cada componente criado precisa de um identificador distinto. Recomendamos a seguinte convenção para melhor legibilidade: use o título do componente, converta para minúsculas, substitua espaços por hífen. Para um componente intitulado 'Minha Tabela Analítica', o identificador seria 'minha-tabela-analitica'.",
    needUniqueId:
      "O identificador fornecido está sendo usado por outro componente no local atual ou em um vinculado. Por favor, forneça um identificador distinto para garantir a exclusividade.",
    numberOfDecimals: "Número de casas decimais",
    numberOfDecimalsTooltip:
      "O número de casas decimais para todos os números exibidos no componente. Observe que isso não inclui os eixos dos gráficos.",
    withBenchmark: "Mostrar com o benchmark",
    footnote: "Texto abaixo do componente",
    placeholderFootnote:
      "Um texto que aparece abaixo do componente. Ex: 'As taxas estão incluídas.'",
    langTabError: "Alguns campos são inválidos nos seguintes idiomas:",
    settingsOnlyUsedOnOverviewTooltip:
      "Esta configuração afeta exclusivamente a página 'Visão Geral'.",
    showOnFactsheet: "Mostrar no Factsheet",
    tabError: "Alguns campos são inválidos",
    title: "Título",
    titleEdit: (ctx) => `Editar ${ctx.named("componentId")}`,
    titleAdd: "Adicionar componente",
    unsavedChangesAlert: "Você tem alterações não salvas. Se sair sem salvar, elas serão perdidas.",
    factsheetFooter: {
      saveSuccess: "O rodapé foi atualizado com sucesso.",
      title: "Rodapé",
      text: "Texto",
      showPageNumber: "Mostrar número da página",
      showFooterLine: "Mostrar linha do rodapé",
    },
    factsheetHeader: {
      backgroundImage: "Imagem de fundo",
      backgroundImageTooltip:
        "As dimensões do cabeçalho são 794x150, por isso utilize uma imagem com dimensões que sigam o mesmo rácio para obter o melhor resultado.",
      hideProductName: "Ocultar nome do produto",
      logo: "Logotipo",
      onlyOnFirstPage: "Somente na primeira página",
      saveSuccess: "O cabeçalho foi atualizado com sucesso.",
      textInPrimary: "Texto na cor da marca",
      title: "Cabeçalho",
      useCompanyLogo: "Usar logotipo da empresa",
      datePosition: {
        bottomLeft: "Inferior esquerdo",
        bottomRight: "Inferior direito",
        standard: "Padrão",
        title: "Posição da data",
        topLeft: "Superior esquerdo",
        topRight: "Superior direito",
      },
    },
    factsheetStyle: {
      primaryColor: "Cor primária",
      saveSuccess: "O estilo foi atualizado com sucesso.",
      title: "Estilo",
      fontSize: {
        normal: "Normal",
        large: "Grande",
        small: "Pequena",
        title: "Tamanho da fonte",
      },
      spacing: {
        comfortable: "Confortável",
        compact: "Compacto",
        title: "Espaçamento",
      },
      titleBorder: {
        above: "Acima do título",
        below: "Abaixo do título",
        none: "Sem borda",
        title: "Borda do título",
      },
    },
    genericComponent: {
      addColumn: "Adicionar coluna",
      addRow: "Adicionar linha",
      addData: "Adicionar dados",
      cantChangeType:
        "Você não pode alterar o tipo de um componente genérico depois de salvá-lo. Se deseja um tipo diferente, precisa criar um novo componente genérico.",
      columnWithIndex: (ctx) => `Coluna ${ctx.named("idx")}`,
      data: "Dados",
      date: "Data",
      displayValuesAsPerc: "Apresenta valores como percentagem",
      displayValuesAsPercTooltip: "Apresenta valores com um símbolo '%' no gráfico",
      donutChart: "Gráfico de rosca",
      exampleSector: "Ex: Setores",
      header: "Cabeçalho",
      horizontalBarChart: "Gráfico de barras horizontal",
      key: "Chave",
      image: "Imagem",
      lineChart: "Gráfico de linhas",
      needData:
        "Você não pode salvar um componente genérico sem dados ou com dados parciais. Exclua-o, adicione dados ou complete os dados presentes antes de salvar.",
      pieChart: "Gráfico de pizza",
      removeData: "Remover dados",
      showColumnHeader: "Mostrar cabeçalho da coluna",
      table: "Tabela",
      text: "Texto",
      type: "Tipo",
      useBrandColor: "Usar cor da marca",
      value: "Valor",
      verticalBarChart: "Gráfico de barras verticais",
      video: "Vídeo",
      weightPercent: "Peso (em %)",
    },
    contacts: {
      addContact: "Adicionar contato",
      additionalText: "Texto adicional",
      email: "Email",
      exampleEmail: "Ex: jdoe@mail.com",
      exampleName: "Ex: John Doe",
      examplePhone: "Ex: +41 79 123 45 67",
      exampleRole: "Ex: Sócio",
      imageSquare: "Imagem (quadrada)",
      name: "Nome",
      phone: "Telefone",
      removeContact: "Remover contato",
      role: "Função",
    },
    dialog: {
      copyInAllLangs: {
        title: "Copiar em todos os idiomas",
        text: "Você vai copiar todos os valores do idioma atual para todos os outros idiomas. Tem certeza?",
      },
      saveFieldInAllShareClasses: {
        title: "Substituir",
        text: "Você vai substituir este campo em todas as outras classes de ações deste produto, não apenas na classe atual. Tem certeza?",
      },
      saveInAllShareClasses: {
        title: "Substituir",
        text: (ctx) =>
          `Você vai substituir os dados de "${ctx.named(
            "tabName"
          )}" de todas as outras classes de ações deste produto pelos dados de "${ctx.named(
            "tabName"
          )}" desta classe de ações. Tem certeza?`,
      },
    },
    description: {
      contentTitle: "Descrição",
    },
    disclaimer: {
      contentTitle: "Aviso legal",
      defaultWarning:
        "Atualmente, você não tem um aviso legal para este produto. Portanto, o aviso padrão foi utilizado. Se você escrever um aviso legal aqui, ele será exibido apenas para este produto.",
    },
    historicalPricesChart: {
      adjusted: "Utilizar preços ajustados",
      adjustedTooltip:
        "Geralmente, os preços ajustados significam preços que são ajustados para dividendos, desdobramentos e outros eventos. O preço não ajustado refere-se simplesmente ao custo de uma ação do produto no final do dia.",
      defaultLogScale: "Por padrão, em escala logarítmica",
      showLogScaleSwitch: "Mostrar alternador de escala logarítmica",
      priceIndexedTo100: "Utilizar preços indexados a 100",
    },
    holdingOverTime: {
      elementsInOthers: {
        title: 'Elementos a considerar como "Outros"',
        multiSelect: {
          placeholder: "Selecionar elementos",
          selectLabel: "Pressione Enter para selecionar",
          deselectLabel: "Pressione Enter para remover",
          selectedLabel: "Selecionado",
          limitText: (ctx) => `e mais ${ctx.named("count")}`,
        },
      },
    },
    keyCharacteristics: {
      addKeyCharacteristics: "Adicionar características principais",
      exampleDynamicExposure: "Ex: Exposição dinâmica",
      exampleWeDynamicallyAdapt: "Ex: Adaptamos dinamicamente ...",
      header: "Cabeçalho",
      icon: "Ícone",
      paragraph: "Parágrafo",
      removeKeyCharacteristics: "Remover característica principal",
    },
    monthlyReturnsChart: {
      startDate: "Data de início",
      startDateTooltip:
        "Selecione uma data de início (no formato AAAA-MM-DD) para o gráfico. O gráfico será ajustada automaticamente para exibir dados a partir da data mais antiga disponível para o produto ou da data escolhida, o que for mais recente. Deixar este campo em branco exibirá todos os dados de preço disponíveis para o produto.",
    },
    monthlyReturnsTable: {
      descending: "Classificar anos em ordem decrescente",
      startDate: "Data de início",
      startDateTooltip:
        "Selecione uma data de início (no formato AAAA-MM-DD) para a tabela de retornos. A tabela final será ajustada automaticamente para exibir dados a partir da data mais antiga disponível para o produto ou da data escolhida, o que for mais recente. Deixar este campo em branco exibirá todos os dados de preço disponíveis para o produto.",
      benchmark: "Benchmark",
      product: "Produto",
      return: "Retorno",
      nameReplacements: "Substituir nomes padrão",
      nameReplacementsTooltip:
        "Personalize os nomes do produto e do benchmark no componente. Deixe em branco para manter os nomes existentes.",
    },
    notify: {
      componentSaveSuccess: (ctx) =>
        `Seu componente '${ctx.named("componentId")}' foi atualizado com sucesso.`,
    },
    overviewHeader: {
      backgroundImage: "Imagem de fundo",
      backgroundImageTooltip:
        "Observe que a imagem atualmente em consideração também é utilizada como a imagem do cartão do produto na página inicial. Consequentemente, qualquer alteração nessa imagem afetará simultaneamente a representação do produto na página inicial.",
      text: "Texto",
      textTooltip:
        "Observe que o texto atualmente em consideração também é utilizado como o texto do cartão do produto na página inicial. Consequentemente, qualquer alteração nesse texto afetará simultaneamente a representação do produto na página inicial.",
      saveSuccess: "O cabeçalho foi atualizado com sucesso.",
      showDownloadLastFactsheetButton:
        'Mostrar botão "@:components.extras.downloadLastFactsheet.downloadLastFactsheet"',
      showDownloadLastFactsheetButtonTooltip:
        'Para que o botão esteja visível para um usuário, um documento deve ser marcado como "@:pages.products.documents.prompt.uploadFile.shouldConsiderLastFactsheet" na seção "Documentos", e deve estar disponível no idioma selecionado pelo usuário.',
      showFollowButton: "Mostrar botão de seguir",
      title: "Editar cabeçalho de visão geral",
    },
    predefinedHoldingComponent: {
      chartType: "Tipo de gráfico",
      donutChart: "Gráfico de rosca",
      horizontalBarChart: "Gráfico de barras horizontal",
      pieChart: "Gráfico de pizza",
      table: "Tabela",
      useBrandColor: "Usar cor da marca",
      assetClasses: {
        editAssetClasses: "Editar classes de ativos",
      },
      currencyExposure: {
        editCurrencyExposure: "Editar exposição cambial",
      },
      instrumentTypes: {
        editInstrumentTypes: "Editar tipos de instrumentos",
      },
      onlyShowTopThenOthers: {
        title: "Mostrar apenas os principais",
        tooltip:
          'Mostrar apenas os X principais (número escolhido) e mesclar os restantes em "Outros". Deixe em branco para mostrar todos.',
      },
      regions: {
        editRegions: "Editar regiões",
      },
      sectors: {
        editSectors: "Editar setores",
      },
    },
    productDetails: {
      addDetail: "Adicionar detalhe",
      exampleIsin: "Ex: ISIN",
      exampleLU: "Ex: LU2220388479",
      key: "Chave",
      noteDragAndDrop: "Nota: Arraste e solte as linhas para reordenar os detalhes.",
      removeDetail: "Remover detalhe",
      showAuM: "Mostrar AuM",
      showLastPrice: "Mostrar último preço",
      value: "Valor",
    },
    relativePerformanceChart: {
      footnote: "Nota de rodapé",
      placeholderFootnote:
        "Um texto que aparece abaixo do gráfico. Ex: 'As taxas estão incluídas.'",
    },
    riskProfile: {
      level: "Nível de risco",
    },
    shareClassesTable: {
      bloomberg: "Bloomberg",
      class: "Classe",
      defaultFootnote:
        "SI perf.: os retornos para períodos desde a criação de mais de um ano são anualizados, e não anualizados se menos de um ano.",
      footnote: "Nota de rodapé",
      inceptionDate: "Data de criação",
      isin: "ISIN",
      mgmtFee: "Taxa de gestão",
      perfFee: "Taxa de desempenho",
      title: "Editar tabela de classes de ações",
    },
    statistics: {
      benchmark: "Benchmark",
      product: "Produto",
      nameReplacements: "Substituir nomes padrão",
      nameReplacementsTooltip:
        "Personalize os nomes do produto e do benchmark no componente. Deixe em branco para manter os nomes existentes. Por exemplo, use um termo como 'Produto' para abreviar e economizar espaço na exibição.",
      fieldsToDisplay: "Campos a serem exibidos",
      noteDragAndDrop: "Nota: Arraste e solte as linhas para reordenar as estatísticas exibidas.",
      shownStatisticName: "Nome da estatística exibida",
      showStatistic: "Mostrar estatística",
      statisticId: "ID da estatística",
    },
    statisticsWithPeriods: {
      benchmark: "Benchmark",
      product: "Produto",
      nameReplacements: "Substituir nomes padrão",
      nameReplacementsTooltip:
        "Personalize os nomes do produto e do benchmark no componente. Deixe em branco para manter os nomes existentes. Por exemplo, use um termo como 'Produto' para abreviar e economizar espaço na exibição.",
      fieldsToDisplay: "Campos a serem exibidos",
      noteDragAndDrop: "Nota: Arraste e solte as linhas para reordenar as estatísticas exibidas.",
      periodsTitle: "Períodos a serem exibidos",
      shownStatisticName: "Nome da estatística exibida",
      showStatistic: "Mostrar estatística",
      statisticId: "ID da estatística",
      periods: {
        1: "1M",
        3: "3M",
        6: "6M",
        ytd: "YTD",
        12: "1A",
        36: "3A",
        60: "5A",
        max: "MAX",
      },
    },
    topHoldings: {
      fieldsToDisplay: "Campos a serem exibidos",
      showHeader: "Mostrar cabeçalho",
      showTotal: "Mostrar total",
      numHoldings: {
        title: "Número de participações a exibir",
        tooltip:
          "Se você deixar este campo em branco, todos os dados disponíveis para as participações do produto serão exibidos.",
      },
      holdingsToIgnore: {
        title: "Participações a ignorar",
        holdingsMultiselect: {
          placeholder: "Selecionar participações a ignorar",
          selectLabel: "Pressione Enter para selecionar",
          deselectLabel: "Pressione Enter para remover",
          selectedLabel: "Selecionado",
          limitText: (ctx) => `e mais ${ctx.named("count")}`,
        },
      },
      customizeTable: "Personalizar tabela",
      addColumn: "Adicionar coluna",
      columnWithIndex: (ctx) => `Coluna ${ctx.named("idx")}`,
    },
  },
  extras: {
    changeRoles: {
      changeRoles: "Alterar funções",
      notify: {
        errorText: "Nenhuma função foi atualizada. Motivo: ",
        successText: "Funções do usuário foram atualizadas",
      },
    },
    deleteGenericComponent: {
      delete: "Excluir",
      deleteAllComponents: "Excluir todos os componentes",
      deleteOnlyThisComponent: "Excluir apenas este componente",
      deleting: "Excluindo ...",
      deleteSuccess: "Exclusão bem-sucedida.",
      page: "Página",
      shareClass: "Classe de ações",
      textIsNotLinked:
        "Você está prestes a excluir permanentemente um componente genérico. Esta ação não pode ser desfeita. Por favor, confirme se deseja prosseguir com a exclusão.",
      textIsLinkedFirstPart:
        "Você está prestes a excluir permanentemente um componente genérico. Esta ação não pode ser desfeita. Observe que este componente está vinculado ao mesmo componente nas seguintes localizações: ",
      textIsLinkedSecondPart:
        "Por favor, confirme se deseja prosseguir com a exclusão e decida se deseja excluir apenas o componente atual ou se deseja excluir este componente e todos os componentes vinculados.",
      title: (ctx) => `Exclusão permanente de ${ctx.named("componentId")}`,
      pageList: {
        factsheet: "Factsheet",
        overview: "Visão geral",
      },
    },
    deleteUser: {
      button: "Excluir",
      dialog: {
        confirm: "Excluir usuário",
        text: "Tem certeza de que deseja excluir permanentemente este usuário?",
        title: "Excluir usuário",
      },
      notify: {
        errorText: "Falha ao excluir o usuário.",
        successText: "Usuário foi excluído.",
      },
    },
    downloadLastFactsheet: {
      downloadLastFactsheet: "Baixar último factsheet",
      downloadingLastFactsheet: "Baixando...",
      needLogin: {
        text: "Para baixar este documento, você precisa estar autenticado.",
        title: "Você precisa estar autenticado",
      },
    },
    manualVerifyEmail: {
      forceVerification: "Forçar verificação de e-mail",
      verified: "E-mail verificado",
      notify: {
        errorText: "A verificação forçada de e-mail falhou.",
        successText: "O e-mail do usuário foi verificado manualmente.",
      },
    },
    riskProfile: {
      higherRisk: "Maior risco",
      lowerRisk: "Menor risco",
      potentiallyHigherReward: "Potencialmente maior retorno",
      potentiallyLowerReward: "Potencialmente menor retorno",
    },
  },
  factsheet: {
    lastPrice: "Último preço",
  },
  graphs: {
    logScale: "Escala logarítmica",
    notEnoughData:
      "Não há dados suficientes para plotar este gráfico, selecione um intervalo de datas maior.",
    needMoreData:
      "Para calcular essa métrica, é necessário um certo número de dados no início. Aumente o período de tempo para acessar essa métrica.",
  },
  holdingData: {
    others: "Outros",
  },
  images: {
    uploadImage: {
      darkening: "Escurecimento",
      file: "Arquivo",
      noFile: "Nenhum arquivo",
      noImage: "Nenhuma imagem",
      selectFile: "Selecionar arquivo",
      selectNewImage: "Selecionar nova imagem",
      errorTitleTooLarge: "Imagem muito grande",
      errorMessageTooLarge:
        "A imagem é muito grande. Por favor, selecione outra imagem com tamanho inferior a 30 MB.",
      warningTitleVeryLarge: "Imagem muito grande",
      warningMessageVeryLarge:
        "A imagem é muito grande. Isso tornará as páginas que exibem esta imagem mais lentas para carregar. Para melhor desempenho, recomenda-se usar imagens menores que 10 MB.",
    },
  },
  linkPrompt: {
    componentLinkedTo: "Este componente está vinculado a:",
    currentPage: "Página atual",
    currentShareClass: "Classe de ações atual",
    linkThroughPagesTooltip:
      'Habilitar este recurso cria uma conexão sincronizada entre componentes em várias páginas. Por exemplo, vincular as páginas "Factsheet" e "Visão Geral" garante que qualquer edição em um componente compartilhado seja atualizada simultaneamente em ambas as páginas. Importante: O conteúdo do componente vinculado é duplicado da página de origem que você está editando no momento. Se você iniciar o vínculo ao editar o componente "descrição" da página "Factsheet", o componente "descrição" do "Factsheet" será copiado para a "Visão Geral" e, assim, substituirá o componente "descrição" da "Visão Geral".',
    linkThroughShareClassesTooltip:
      'Habilitar este recurso cria uma conexão sincronizada entre componentes em várias classes de ações. Por exemplo, vincular "Minha Classe de Ações A" e "Minha Classe de Ações B" garante que qualquer edição em um componente compartilhado seja atualizada simultaneamente em ambas as classes de ações. Importante: O conteúdo do componente vinculado é duplicado da página de origem que você está editando no momento. Se você iniciar o vínculo ao editar o componente "descrição" da página "Minha Classe de Ações A", o componente "descrição" da "Minha Classe de Ações A" será copiado para a "Minha Classe de Ações B" e, assim, substituirá o componente "descrição" da "Minha Classe de Ações B".',
    linkedWithPages: "Vinculado às seguintes páginas",
    linkedWithShareClasses: "Vinculado às seguintes classes de ações",
    page: "Página",
    shareClass: "Classe de ações",
    title: (ctx) => `Editar vínculo de ${ctx.named("componentId")}`,
    unlink: "Desvincular",
    unlinking: "Desvinculando ...",
    unsavedChangesAlert: "Você tem alterações não salvas. Se sair sem salvar, elas serão perdidas.",
    confirmSaveDialog: {
      computingNewLinks: "Calculando novos vínculos...",
      onceLinked:
        "Uma vez vinculado, quando uma alteração for aplicada a um componente em qualquer um desses locais (incluindo o atual), todos os outros componentes vinculados serão atualizados da mesma forma. Por favor, confirme se deseja prosseguir com a gravação.",
      overwrittenExistingComponent: "Substituindo um componente com o mesmo id",
      overwrittenExistingComponentTooltip: (ctx) =>
        `Aviso: Já existe um componente com ID '${ctx.named(
          "componentId"
        )}' neste local, portanto ele será substituído por este componente se você vinculá-lo.`,
      title: "Confirmar salvamento de novos vínculos",
      youAreAboutToLink:
        "Você está prestes a vincular o componente atual aos componentes nas seguintes localizações:",
    },
    factsheetFooter: {
      title: "Editar ligações no rodapé da factsheet",
      saveSuccess: "A atualização das ligações de rodapé foi bem-sucedida.",
    },
    factsheetHeader: {
      title: "Editar ligações no cabeçalho da factsheet",
      saveSuccess: "A atualização das ligações do cabeçalho foi bem-sucedida.",
    },
    factsheetStyle: {
      title: "Editar ligações no estilo da factsheet",
      saveSuccess: "A atualização das ligações de rodapé foi bem-sucedida.",
    },
    notify: {
      linkSaveSuccess: (ctx) =>
        `A atualização de vínculo de '${ctx.named("componentId")}' foi bem-sucedida.`,
    },
    multiselect: {
      placeholder: "Selecione páginas para vincular",
      selectLabel: "Pressione Enter para selecionar",
      deselectLabel: "Pressione Enter para remover",
      selectedLabel: "Selecionado",
    },
    overviewHeader: {
      title: "Editar vínculo do cabeçalho da visão geral",
      saveSuccess: "A atualização de vínculo do cabeçalho foi bem-sucedida.",
    },
    pageList: {
      factsheet: "Factsheet",
      overview: "Visão Geral",
      analysis: "Análise",
    },
  },
  pricesManagement: {
    addData: "Adicionar dados",
    autoDataUpdate: "Seus dados são atualizados automaticamente.",
    date: "Data",
    datePlaceholder: "AAAA-MM-DD",
    dateValidationError: "Você não pode adicionar uma data que já possui um valor.",
    exportCsvButton: "Exportar como CSV",
    importCsvButton: "Importar de CSV",
    loadingData: "Carregando dados...",
    price: "Preço",
    priceAdj: "Preço (ajustado)",
    priceAdjTooltip:
      "Geralmente, os preços ajustados significam preços que são ajustados para dividendos, desdobramentos e outros eventos. Se não for fornecido, assume-se que é o mesmo que o preço.",
    priceAdjTooltipTable:
      "Geralmente, os preços ajustados significam preços que são ajustados para dividendos, desdobramentos e outros eventos.",
    removeData: "Remover dados",
    benchmarks: {
      noBenchmark: "Nenhum benchmark disponível para este produto.",
    },
    dialog: {
      removeData: {
        title: "Remover um ponto de dados",
        text: "Você está prestes a remover completamente um ponto de dados. Tem certeza?",
      },
    },
    importCsvText: {
      para1:
        "Espera-se que o ficheiro de entrada esteja no formato CSV, com duas ou três colunas e uma linha de cabeçalho.",
      para2:
        "A primeira coluna deve consistir em datas e a segunda coluna deve consistir em números (o preço na data correspondente). A terceira coluna é opcional e, se for fornecida, deve consistir em números correspondentes ao preço ajustado; se não for fornecida, assume-se que os preços ajustados são os mesmos que os preços.",
      para3:
        "Certifique-se de que o seu CSV é válido para evitar quaisquer problemas aquando da leitura e análise dos dados.",
    },
    notify: {
      dateUsedMultipleTimeError:
        "Existem vários preços associados a uma data. Você deve garantir que cada data tenha um preço único atribuído a ela.",
      nanFound:
        "Foram encontrados alguns valores vazios. Certifique-se de que todas as linhas e colunas contêm um valor.",
      numberFormatError:
        'Pelo menos um dos números tem um formato não reconhecido. Idealmente, você não deve usar separadores para milhares e usar um ponto como separador decimal, pois este é o formato esperado. Por exemplo, "102.12", "1380.25", "18.952", ...',
      saveErrorText: "Erro ao atualizar os preços, entre em contato conosco.",
      saveSuccessText: "Os preços foram atualizados com sucesso.",
    },
    prompt: {
      addPrice: "Adicionar um preço",
      importCsv: "Importar dados de CSV",
    },
  },
  tables: {
    metricsTable: {
      na: "N/D",
      name: "Nome",
      showLess: "Mostrar menos",
      showMore: "Mostrar mais",
    },
    monthlyReturnsTable: {
      benchmark: "Benchmark",
      return: "Retorno",
    },
    performanceTable: {
      computedFromTo: (ctx) =>
        `Desempenho calculado de ${ctx.named("firstDate")} a ${ctx.named("lastDate")}.`,
      notAvailableData:
        "Dados indisponíveis, seja porque não há dias suficientes para calcular, os dados não são significativos (ex.: sharpe ratio negativo) ou o benchmark não está sempre disponível durante o período selecionado.",
    },
    topHoldingsTable: {
      holdingFields: {
        assetClass: "Classe de ativo",
        currency: "Moeda",
        holdingName: "Nome",
        instrumentType: "Tipo de instrumento",
        isin: "ISIN",
        region: "Região",
        sector: "Setor",
        value: "Valor",
        weight: "Peso",
      },
      totalOfPortfolio: "Total do portfólio",
    },
  },
  thirdParty: {
    vuePhoneNumberInput: {
      countrySelectorLabel: "Código do país",
      countrySelectorError: "Selecione um país",
      phoneNumberLabel: "Número de telefone",
      example: "Exemplo :",
    },
  },
  videos: {
    uploadVideos: {
      file: "Arquivo",
      noFile: "Nenhum arquivo",
      noVideo: "Nenhuma vídeo",
      selectFile: "Selecionar arquivo",
      selectNewVideo: "Selecionar nova vídeo",
      errorTitleTooLarge: "Vídeo muito grande",
      errorMessageTooLarge:
        "A vídeo é muito grande. Por favor, selecione outra vídeo com tamanho inferior a 30 MB.",
      warningTitleVeryLarge: "Vídeo muito grande",
      warningMessageVeryLarge:
        "A vídeo é muito grande. Isso tornará as páginas que exibem esta vídeo mais lentas para carregar. Para melhor desempenho, recomenda-se usar vídeos menores que 10 MB.",
    },
  },
};
