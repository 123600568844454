import { flattenDeep } from "lodash";

import config from "@/config";

import { beforeEnterChangeLocale, buildPathWithLang } from "./utils";

const products = config.products ?? [];

let productsRoutes = [];
let embeddedProductsRoutes = [];
let productsFactsheetGenerateRoutes = [];

products.forEach((productConfig) => {
  const productRoutes = [];
  const embeddedProductRoutes = [];
  // Always add an Overview route.
  productRoutes.push({
    path: buildPathWithLang(`/products/${productConfig.productId}/overview`),
    name: `products_${productConfig.storeModule}`,
    component: () => import("../views/products/DefaultOverview.vue"),
    meta: {
      productId: productConfig.productId,
      authorize: productConfig.allowedRoles ?? config.allowedRoles,
      // If the user doesn't have the role to see it, but has the admin right for one of the
      // share class, we should still let access to it.
      allowAdminOfOneShareClass: true,
    },
    props: {
      productId: productConfig.productId,
      lockedBehindLogin: productConfig.lockedBehindLogin,
      storeModule: productConfig.storeModule,
      productName: productConfig.productName,
      productShareClassRoot: productConfig.productShareClassRoot,
      mode: productConfig.live?.mode,
      updateFrequency: productConfig.live?.updateFrequency,
      showNextSubscriptionAndRedemption: productConfig.showNextSubscriptionAndRedemption,
      timelineData: productConfig.timelineData,
    },
    beforeEnter: beforeEnterChangeLocale,
  });

  // TODO: Remove this at some point when people don't try to access Alvola by this old link.
  if (process.env.VUE_APP_ORG_ID == "alquant" && productConfig.productId == "alvola") {
    productRoutes.push({
      path: buildPathWithLang(`/products/alvola/analysis`),
      redirect: `/products/alvola/overview`,
    });
  }

  // Only add the Live Analysis route if it is configured.
  if (productConfig.live) {
    productRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/live-analysis`),
      name: `products_${productConfig.storeModule}_liveAnalysis`,
      component: () =>
        productConfig.live.componentPath
          ? import(`../views/products/${productConfig.live.componentPath}`)
          : import("../views/products/DefaultAnalysis.vue"),
      meta: {
        productId: productConfig.productId,
        authorize:
          productConfig.live.allowedRoles ?? productConfig.allowedRoles ?? config.allowedRoles,
        // If the user doesn't have the role to see it, but has the admin right for one of the
        // share class, we should still let access to it.
        allowAdminOfOneShareClass: true,
      },
      props: {
        productId: productConfig.productId,
        lockedBehindLogin: productConfig.lockedBehindLogin,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productShareClassRoot: productConfig.productShareClassRoot,
        genericCardsToShow: productConfig.live.genericCardsToShow,
        perfStats: productConfig.live.perfStats,
        productColor: productConfig.productColor,
        allowLiveComparison: productConfig.live.allowLiveComparison,
        events: productConfig.live.events,
        allowLogScale: productConfig.live.allowLogScale,
        defaultLogScale: productConfig.live.defaultLogScale,
        mode: productConfig.live.mode,
        tabmenuItems: productConfig.tabmenuItems,
        tabmenuText: productConfig.live.tabmenuText,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    embeddedProductRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/embedded/live-perf-graph`),
      name: `products_${productConfig.storeModule}_embedded_livePerfGraph`,
      component: () => import("../views/products/DefaultEmbeddedPerfGraph.vue"),
      meta: {
        noNeedOfInvestorProfile: true,
      },
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productColor: productConfig.productColor,
        allowLogScale: productConfig.live.allowLogScale,
        defaultLogScale: productConfig.live.defaultLogScale,
        mode: productConfig.live.mode,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    embeddedProductRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/embedded/live-stats-table`),
      name: `products_${productConfig.storeModule}_embedded_liveStatsTable`,
      component: () => import("../views/products/DefaultEmbeddedStatsTable.vue"),
      meta: {
        noNeedOfInvestorProfile: true,
      },
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productColor: productConfig.productColor,
        perfStats: productConfig.live.perfStats,
        mode: productConfig.live.mode,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    embeddedProductRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/embedded/live-perf-table`),
      name: `products_${productConfig.storeModule}_embedded_livePerfTable`,
      component: () => import("../views/products/DefaultEmbeddedPerfTable.vue"),
      meta: {
        noNeedOfInvestorProfile: true,
      },
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
      },
      beforeEnter: beforeEnterChangeLocale,
    });
  }

  // Only add the Historical Analysis route if it is configured.
  if (productConfig.historical) {
    productRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/historical-analysis`),
      name: `products_${productConfig.storeModule}_historicalAnalysis`,
      component: () =>
        productConfig.historical.componentPath
          ? import(`../views/products/${productConfig.historical.componentPath}`)
          : import("../views/products/DefaultAnalysis.vue"),
      meta: {
        productId: productConfig.productId,
        authorize:
          productConfig.historical.allowedRoles ??
          productConfig.allowedRoles ??
          config.allowedRoles,
        // If the user doesn't have the role to see it, but has the admin right for one of the
        // share class, we should still let access to it.
        allowAdminOfOneShareClass: true,
      },
      props: {
        productId: productConfig.productId,
        lockedBehindLogin: productConfig.lockedBehindLogin,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productShareClassRoot: productConfig.productShareClassRoot,
        genericCardsToShow: productConfig.historical.genericCardsToShow,
        perfStats: productConfig.historical.perfStats,
        productColor: productConfig.productColor,
        allowLiveComparison: productConfig.historical.allowLiveComparison,
        events: productConfig.historical.events,
        allowLogScale: productConfig.historical.allowLogScale,
        defaultLogScale: productConfig.historical.defaultLogScale,
        mode: productConfig.historical.mode,
        tabmenuItems: productConfig.tabmenuItems,
        tabmenuText: productConfig.historical.tabmenuText,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    embeddedProductRoutes.push({
      path: buildPathWithLang(
        `/products/${productConfig.productId}/embedded/historical-perf-graph`
      ),
      name: `products_${productConfig.storeModule}_embedded_historicalPerfGraph`,
      component: () => import("../views/products/DefaultEmbeddedPerfGraph.vue"),
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productColor: productConfig.productColor,
        allowLogScale: productConfig.historical.allowLogScale,
        defaultLogScale: productConfig.historical.defaultLogScale,
        mode: productConfig.historical.mode,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    embeddedProductRoutes.push({
      path: buildPathWithLang(
        `/products/${productConfig.productId}/embedded/historical-stats-table`
      ),
      name: `products_${productConfig.storeModule}_embedded_historicalStatsTable`,
      component: () => import("../views/products/DefaultEmbeddedStatsTable.vue"),
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productColor: productConfig.productColor,
        mode: productConfig.historical.mode,
      },
      beforeEnter: beforeEnterChangeLocale,
    });
  }

  // Only add the Internal Analysis route if it is configured.
  if (productConfig.internal) {
    productRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/internal-analysis`),
      name: `products_${productConfig.storeModule}_internalAnalysis`,
      component: () =>
        productConfig.internal.componentPath
          ? import(`../views/products/${productConfig.internal.componentPath}`)
          : import("../views/products/DefaultAnalysis.vue"),
      meta: {
        productId: productConfig.productId,
        authorize:
          productConfig.internal.allowedRoles ?? productConfig.allowedRoles ?? config.allowedRoles,
        // If the user doesn't have the role to see it, but has the admin right for one of the
        // share class, we should still let access to it.
        allowAdminOfOneShareClass: true,
      },
      props: {
        productId: productConfig.productId,
        storeModule: productConfig.storeModule,
        productName: productConfig.productName,
        productShareClassRoot: productConfig.productShareClassRoot,
        genericCardsToShow: productConfig.internal.genericCardsToShow,
        perfStats: productConfig.internal.perfStats,
        productColor: productConfig.productColor,
        allowLiveComparison: productConfig.internal.allowLiveComparison,
        events: productConfig.internal.events,
        allowLogScale: productConfig.internal.allowLogScale,
        defaultLogScale: productConfig.internal.defaultLogScale,
        mode: productConfig.internal.mode,
        tabmenuItems: productConfig.tabmenuItems,
        tabmenuText: productConfig.internal.tabmenuText,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    // TODO: Do we want to expose the internal perf graph to embed it anywhere,
    // like we do for Live and Historical?
  }

  // Only add the Use Case route if it is configured.
  if (productConfig.useCase) {
    // If a redirect is set, just build push path and add the redirect, otherwise build the route.
    if (productConfig.useCase.redirect) {
      productRoutes.push({
        path: buildPathWithLang(`/products/${productConfig.productId}/use-case`),
        redirect: productConfig.useCase.redirect,
      });
    } else {
      productRoutes.push({
        path: buildPathWithLang(`/products/${productConfig.productId}/use-case`),
        name: `products_${productConfig.storeModule}_useCase`,
        component: () => import(`../views/products/${productConfig.useCase.componentPath}`),
        meta: {
          productId: productConfig.productId,
          authorize: productConfig.allowedRoles ?? config.allowedRoles,
          // If the user doesn't have the role to see it, but has the admin right for one of the
          // share class, we should still let access to it.
          allowAdminOfOneShareClass: true,
        },
        props: {
          storeModule: productConfig.storeModule,
        },
        beforeEnter: beforeEnterChangeLocale,
      });
    }
  }

  // Only add the Factsheet route if it is configured.
  if (productConfig.factsheet) {
    productRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/factsheet`),
      name: `products_${productConfig.storeModule}_factsheet`,
      component: () => import("../views/products/DefaultFactsheet.vue"),
      meta: {
        productId: productConfig.productId,
        authorize:
          productConfig.factsheet.allowedRoles ?? productConfig.allowedRoles ?? config.allowedRoles,
        // If the user doesn't have the role to see it, but has the admin right for one of the
        // product on the platform, we should still let access to it (so that we match what is
        // shown in the left vertical menu).
        allowAdminOfOneProduct: true,
      },
      props: {
        productId: productConfig.productId,
        mode: productConfig.factsheet.mode,
        productName: productConfig.productName,
        productShortName: productConfig.productShortName,
        productColor: productConfig.productColor,
        productShareClassRoot: productConfig.productShareClassRoot,
        showBenchmark: productConfig.factsheet.showBenchmark,
        storeModule: productConfig.storeModule,
        needLoginToSee: productConfig.factsheet.needLoginToSee,
      },
      beforeEnter: beforeEnterChangeLocale,
    });

    // We generate a special page that will help us generate the facthseet in pdf.
    // Note that this page won't be referenced somewhere on the platform, and that
    // we have no specific authorization on it.
    productsFactsheetGenerateRoutes.push({
      path: buildPathWithLang(`/products/${productConfig.productId}/factsheet-for-pdf-generation`),
      name: `products_${productConfig.storeModule}_factsheet-for-pdf-generation`,
      component: () => import("../views/products/DefaultFactsheetForPdfGeneration.vue"),
      meta: {
        productId: productConfig.productId,
        noNeedOfInvestorProfile: true,
      },
      props: {
        productId: productConfig.productId,
        mode: productConfig.factsheet.mode,
        productName: productConfig.productName,
        productShortName: productConfig.productShortName,
        productColor: productConfig.productColor,
        productShareClassRoot: productConfig.productShareClassRoot,
        showBenchmark: productConfig.factsheet.showBenchmark,
        storeModule: productConfig.storeModule,
      },
      beforeEnter: beforeEnterChangeLocale,
    });
  }

  productRoutes.push({
    path: buildPathWithLang(`/products/${productConfig.productId}/documents`),
    name: `products_${productConfig.storeModule}_documents`,
    component: () => import("../views/products/DefaultDocuments.vue"),
    meta: {
      productId: productConfig.productId,
      authorize: productConfig.allowedRoles ?? config.allowedRoles,
      // If the user doesn't have the role to see it, but has the admin right for one of the
      // share class, we should still let access to it.
      allowAdminOfOneShareClass: true,
    },
    props: {
      productId: productConfig.productId,
      lockedBehindLogin: productConfig.lockedBehindLogin,
      storeModule: productConfig.storeModule,
      productName: productConfig.productName,
      productShareClassRoot: productConfig.productShareClassRoot,
    },
    beforeEnter: beforeEnterChangeLocale,
  });

  productRoutes.push({
    path: buildPathWithLang(`/products/${productConfig.productId}/download-document`),
    name: `products_${productConfig.storeModule}_downloadDocument`,
    component: () => import("../views/products/DefaultDocumentDownload.vue"),
    meta: {
      productId: productConfig.productId,
      authorize: productConfig.allowedRoles ?? config.allowedRoles,
      // If the user doesn't have the role to see it, but has the admin right for one of the
      // share class, we should still let access to it.
      allowAdminOfOneShareClass: true,
    },
    props: {
      productId: productConfig.productId,
      storeModule: productConfig.storeModule,
      productName: productConfig.productName,
    },
    beforeEnter: beforeEnterChangeLocale,
  });

  productRoutes.push({
    path: buildPathWithLang(`/products/${productConfig.productId}/download-last-factsheet`),
    name: `products_${productConfig.storeModule}_downloadLastFactsheet`,
    component: () => import("../views/products/DefaultLastFactsheetDownload.vue"),
    meta: {
      productId: productConfig.productId,
      authorize: productConfig.allowedRoles ?? config.allowedRoles,
      // If the user doesn't have the role to see it, but has the admin right for one of the
      // share class, we should still let access to it.
      allowAdminOfOneShareClass: true,
    },
    props: {
      productId: productConfig.productId,
      storeModule: productConfig.storeModule,
    },
    beforeEnter: beforeEnterChangeLocale,
  });

  productsRoutes.push(productRoutes);
  embeddedProductsRoutes.push(embeddedProductRoutes);
});

productsRoutes = flattenDeep(productsRoutes);
embeddedProductsRoutes = flattenDeep(embeddedProductsRoutes);

export { embeddedProductsRoutes, productsFactsheetGenerateRoutes, productsRoutes };
