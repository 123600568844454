import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- AlphaX USA Smart Airbag ----------*****
const alphaxUsaSmartAirbag = {
  productId: "alphax-usa-smart-airbag",
  storeModule: "alphaxUsaSmartAirbag",
  productName: "USA Smart Airbag",
  productColor: ORG_COLOR,
  oldDataEndDate: "2025-01-21",
  allowedRoles: [Role.superAdmin, Role.admin, Role.viewer],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

// ******---------- Pure AMC ----------*****
const alphaxPureAmcUsd = {
  productId: "alphax-pure-amc-usd",
  storeModule: "alphaxPureAmcUsd",
  productName: "PURE (USD I)",
  productShortName: "PURE",
  productColor: ORG_COLOR,
  productShareClassRoot: "alphax-pure-amc",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const alphaxPureAmcChf = cloneDeep(alphaxPureAmcUsd);
alphaxPureAmcChf.productId = "alphax-pure-amc-chf";
alphaxPureAmcChf.storeModule = "alphaxPureAmcChf";
alphaxPureAmcChf.productName = "PURE (CHF Hdg I)";
alphaxPureAmcChf.isDefaultShareClass = false;

const alphaxPureAmcEur = cloneDeep(alphaxPureAmcUsd);
alphaxPureAmcEur.productId = "alphax-pure-amc-eur";
alphaxPureAmcEur.storeModule = "alphaxPureAmcEur";
alphaxPureAmcEur.productName = "PURE (EUR Hdg I)";
alphaxPureAmcEur.isDefaultShareClass = false;

export const ALPHAX_PRODUCTS_DETAILS = [
  alphaxUsaSmartAirbag,
  alphaxPureAmcUsd,
  alphaxPureAmcChf,
  alphaxPureAmcEur,
];

export const ALPHAX_PRODUCTS = ALPHAX_PRODUCTS_DETAILS.map((details) => details.storeModule);
