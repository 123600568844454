import { GenericProductCards } from "@/assets/constants/analysisCards";
import { CALMGARD_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { PerformanceStatistic } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];

const CALMGARD_PERF_STATS = [
  PerformanceStatistic.cumulativeReturn,
  PerformanceStatistic.annualizedReturn,
  PerformanceStatistic.annualizedVolatility,
  PerformanceStatistic.maximumDrawdown,
  PerformanceStatistic.returnToVolatilityRatio,
  PerformanceStatistic.alpha,
  PerformanceStatistic.beta,
  PerformanceStatistic.sharpeRatio,
  PerformanceStatistic.treynorRatio,
  PerformanceStatistic.calmarRatio,
  PerformanceStatistic.sortinoRatio,
  PerformanceStatistic.m2RiskAdjustedPerfMonthly,
  PerformanceStatistic.valueAtRiskOnePercentOneMonth,
  PerformanceStatistic.skewness,
  PerformanceStatistic.kurtosis,
];

const niwaFundUsd = {
  productId: "niwa-fund-usd",
  storeModule: "niwaFundUsd",
  productName: "NIWA Fund USD",
  productShortName: "NIWA Fund USD",
  productColor: ORG_COLOR,
  productShareClassRoot: "niwa-fund-usd",
  isDefaultShareClass: true,
  showNextSubscriptionAndRedemption: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: CALMGARD_PERF_STATS,
    events: CALMGARD_EVENTS,
  },
  factsheet: {},
};

const niwaFundBacktestingUsd = {
  productId: "niwa-fund-backtesting-usd",
  storeModule: "niwaFundBacktestingUsd",
  productName: "NIWA Fund Backtesting USD",
  productShortName: "NIWA Fund USD",
  productColor: ORG_COLOR,
  productShareClassRoot: "niwa-fund-usd",
  showNextSubscriptionAndRedemption: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: CALMGARD_PERF_STATS,
    events: CALMGARD_EVENTS,
  },
  factsheet: {},
};

const niwaFundChfH = {
  productId: "niwa-fund-chf-h",
  storeModule: "niwaFundChfH",
  productName: "NIWA Fund CHF (h)",
  productColor: ORG_COLOR,
  showNextSubscriptionAndRedemption: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: CALMGARD_PERF_STATS,
    events: CALMGARD_EVENTS,
  },
  factsheet: {},
};

const niwaFundGbpH = {
  productId: "niwa-fund-gbp-h",
  storeModule: "niwaFundGbpH",
  productName: "NIWA Fund GBP (h)",
  productColor: ORG_COLOR,
  showNextSubscriptionAndRedemption: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: CALMGARD_PERF_STATS,
    events: CALMGARD_EVENTS,
  },
  factsheet: {},
};

const niwaFundEurH = {
  productId: "niwa-fund-eur-h",
  storeModule: "niwaFundEurH",
  productName: "NIWA Fund EUR (h)",
  productColor: ORG_COLOR,
  showNextSubscriptionAndRedemption: true,
  allowPriceDataModification: true,
  priceModificationUpdateGcpStorage: false,
  langsForDocuments: ["en", "de"],
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.other,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
    ],
    perfStats: CALMGARD_PERF_STATS,
    events: CALMGARD_EVENTS,
  },
  factsheet: {},
};

export const CALMGARD_PRODUCTS_DETAILS = [
  niwaFundUsd,
  niwaFundBacktestingUsd,
  niwaFundChfH,
  niwaFundGbpH,
  niwaFundEurH,
];

export const CALMGARD_PRODUCTS = CALMGARD_PRODUCTS_DETAILS.map((details) => details.storeModule);
