import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- Base Power Energy Basket ----------*****
const basePowerEnergyBasketChf = {
  productId: "base-power-energy-basket-chf",
  storeModule: "basePowerEnergyBasketChf",
  productName: "Base Power Energy Basket - CHF",
  productShortName: "Base Power Energy Basket",
  productColor: ORG_COLOR,
  productShareClassRoot: "base-power-energy-basket",
  isDefaultShareClass: true,
  allowPriceDataModification: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const basePowerEnergyBasketUsd = cloneDeep(basePowerEnergyBasketChf);
basePowerEnergyBasketUsd.productId = "base-power-energy-basket-usd";
basePowerEnergyBasketUsd.storeModule = "basePowerEnergyBasketUsd";
basePowerEnergyBasketUsd.productName = "Base Power Energy Basket - USD";
basePowerEnergyBasketUsd.isDefaultShareClass = false;

// ******---------- ERIYA Global Markets - Sport Plus ----------*****
const eriyaGlobalMarketsSportPlusChf = {
  productId: "eriya-global-markets-sport-plus-chf",
  storeModule: "eriyaGlobalMarketsSportPlusChf",
  productName: "ERIYA Global Markets - Sport Plus",
  productColor: ORG_COLOR,
  allowPriceDataModification: true,
  allowHoldingDataModification: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

export const ERIYA_PRODUCTS_DETAILS = [
  basePowerEnergyBasketChf,
  basePowerEnergyBasketUsd,
  eriyaGlobalMarketsSportPlusChf,
];

export const ERIYA_PRODUCTS = ERIYA_PRODUCTS_DETAILS.map((details) => details.storeModule);
