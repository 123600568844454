// It's a special file in term of imports, we do not want it to be auto-sorted.
/* eslint-disable simple-import-sort/imports */

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons

// TinyMCE
import "tinymce/tinymce";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/models/dom/model";
// Load different needed lang for tinymce.
import "./assets/lang/tinymce.js";
import Editor from "@tinymce/tinymce-vue";
Vue.component("EditorTinymce", Editor);

import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// axios
import axios from "./http/axios/index.js";
Vue.prototype.$http = axios;

// Theme Configurations
import "../themeConfig.js";

// Firebase
import "./firebase/firebaseConfig.js";
import firebase from "firebase/compat/app";
import { setAnalyticsCollectionEnabled } from "@firebase/analytics";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Verte
import "verte/dist/verte.css";

// Tailwind
import "./assets/css/main.css";

// Vue Router
import router from "./router/index";

// Vuex Store
import store from "./store/store";

// Vuexy Admin Filters
import "./filters/filters";

// Vuexfire global option
import { firestoreOptions } from "vuexfire";
firestoreOptions.wait = true;

// VeeValidate
import { ValidationProvider, ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
// Import validation rules
import "./validation/validation.js";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Vue phone number input
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
Vue.component("VuePhoneNumberInput", VuePhoneNumberInput);

// Ag grid styling
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

// reCAPTCHA
import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

// Allow scroll to element on click + keep track of active element
import VueScrollactive from "vue-scrollactive";
Vue.use(VueScrollactive);

import VTooltip from "v-tooltip";
Vue.use(VTooltip, {
  defaultTrigger: "hover focus touch click",
  // We use a huge z-index because we need the popup to appear
  // over the dialog, which has a z-index of 52000.
  defaultClass: "text-xs md:text-base z-[60000]",
});

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import VueScrollTo from "vue-scrollto";
Vue.use(VueScrollTo);

import { iframeResizerContentWindow } from "iframe-resizer/js/index";
Vue.use(iframeResizerContentWindow);

// Initialise Sentry.
import * as Sentry from "@sentry/vue";
if (process.env.VUE_APP_SENTRY_DSN_CLIENT) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN_CLIENT,
    integrations: [
      Sentry.browserTracingIntegration({
        router,
      }),
    ],
    tracePropagationTargets: [
      process.env.VUE_APP_CLIENT_URL,
      process.env.VUE_APP_DOCUMENT_MANAGER_URL,
      process.env.VUE_APP_API_URL,
      /^\//,
    ],
    denyUrls: ["localhost"],
    release: process.env.VUE_APP_SENTRY_RELEASE,
    // Let's register all transactions for now, as we don't have a lot of front-end transactions.
    tracesSampleRate: 1.0,
    logErrors: true,
    environment: process.env.VUE_APP_ALQUANT_ENV,
    ignoreErrors: [
      // It is coming from recaptcha, we can't do anything about it:
      // https://github.com/getsentry/sentry-javascript/issues/2514
      "Non-Error promise rejection captured with value: Timeout",
      // It is coming from users reaching the platform from a link inside outlook:
      // https://github.com/getsentry/sentry-javascript/issues/3440
      /^Non-Error promise rejection captured with value: Object Not Found Matching Id:(\d+), MethodName:(\w+), ParamCount:(\d+)$/,
      // This error means that ResizeObserver was not able to deliver all observations within a single animation frame.
      // This is a well known error: https://sentry.io/answers/react-resizeobserver-loop-completed-with-undelivered-notifications/
      // It can safely be ignored: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded#comment86691361_49384120
      "ResizeObserver loop completed with undelivered notifications.",
      "ResizeObserver loop limit exceeded",
      // Ignoring these for now. We might want to handle these in the future.
      // https://sentry.io/answers/chunk-load-errors-javascript/
      /^Loading CSS chunk \d+ failed\.(.|\n)*$/,
      // It comes from a recent version of iOS, we can ignore it.
      // https://issuetracker.google.com/issues/396043331
      "Can't find variable: gmo",
    ],
  });
}

import {
  SERVER_ERROR_RESPONSE_EVENT,
  SERVER_ERROR_REQUEST_EVENT,
  SERVER_ERROR_OTHER_EVENT,
} from "./assets/constants/analytics";

// Add d3 locale format everywhere. If we don't do this
// the dash is replaced by a minus sign, which is bigger,
// and we have always "worked" with the dash.
import { formatLocale } from "d3";
Vue.prototype.$d3FormatLocale = formatLocale({
  decimal: ".",
  thousands: ",",
  grouping: [3],
  minus: "\u002D",
}).format;

// Add multiple languages feature
import VueI18n from "vue-i18n";
Vue.use(VueI18n);
import { messages } from "./assets/lang/messages.js";
import { LOCALE_KEY } from "./assets/constants/global.js";
import config from "@/config";
// Load saved locale if modified once. If no locale, then
// we need to fallback to possible language for the platform,
// by checking navigator default languages if multiple languages
// possible.
let baseLang = localStorage.getItem(LOCALE_KEY);
if (!baseLang) {
  if (config.availableLanguages.length > 1) {
    navigator.languages
      ?.map((navLang) => navLang.split("-")[0])
      ?.forEach((navLang) => {
        if (!baseLang && config.availableLanguages.includes(navLang)) {
          baseLang = navLang;
        }
      });
  }

  // If we couldn't find a suitable lang from the browser,
  // or if there isn't multiple languages,
  // we default to first available.
  if (!baseLang) {
    baseLang = config.availableLanguages[0];
  }

  // If nothing was saved in localStorage, we now save this value to be sure to have
  // one in there.
  localStorage.setItem(LOCALE_KEY, baseLang);
} else {
  // If we had previously a lang on the platform and then it was removed, we need to check
  // if the removed lang was saved in the local storage, and if it's the case, we need to
  // overwrite it.
  if (!config.availableLanguages.includes(baseLang)) {
    baseLang = config.availableLanguages[0];
    localStorage.setItem(LOCALE_KEY, baseLang);
  }
}

export const i18n = new VueI18n({
  locale: baseLang,
  fallbackLocale: "en",
  messages,
});

// We use moment for formatting our dates through the platform
// hence it's necessary to set the correct locale at the initialization
// of the platform.
import moment from "moment";
moment.locale(baseLang);

// Disable collection for Google Analytics if the org doesn't want it to be activated.
setAnalyticsCollectionEnabled(firebase.analytics(), config.disableGoogleAnalytics !== true);
// Set Google Analytics user property linked to language.
firebase.analytics().setUserProperties({ platform_lang: baseLang });

// Feather font icon
require("./assets/css/iconfont.css");

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

let app, initialApp;

// Function that links data from the local storage. Needed before the app is mounted.
function bindBrowserLocalStorage() {
  return Promise.all([
    store.dispatch("allProducts/initShareClassRootToProductIdToShow"),
    store.dispatch("userInfo/setIpTypeFromBrowserLocalStorage"),
    store.dispatch("userInfo/setIpCountryFromBrowserLocalStorage"),
  ]);
}

// Function that binds firestore with our stores. Needed before the app is mounted.
function bindGlobalVuexfire() {
  return Promise.all([
    store.dispatch("platformSettings/bindPlatformSettingsLangs"),
    store.dispatch("platformSettings/bindPlatformSettings"),
    store.dispatch("allProducts/bindProductsConfigFirestore"),
  ]);
}

// The method that will finally mount the app and show a page to the user.
async function mountApp() {
  if (initialApp !== null) {
    app.$mount("#app");

    // Note that we need to wait for a tick because the watcher that triggers
    // this "mountApp" method is inside "initialApp", and because the watcher
    // is "immediate: True", maybe the variable assignment hasn't been done yet,
    // hence we can't "$destroy" it right after.
    await app.$nextTick();

    // Clean now usless vue app.
    initialApp.$destroy();
    initialApp = null;
  }
}

// If the page doesn't load within 60 seconds, prompt the user to refresh. Visitors outside of
// Europe may experience longer load times, so we've set a higher timeout.
setTimeout(() => {
  if (initialApp !== null) {
    document.getElementById("intial-index-spinner").style["display"] = "none";
    document.getElementById("intial-index-refresh-button").style["display"] = "block";

    // Forbid the mounting of the app even if it eventually succeeds if we reach this state.
    initialApp = null;
  }
}, 60000);

firebase.auth().onAuthStateChanged(async (user) => {
  const promises = [bindGlobalVuexfire(), bindBrowserLocalStorage()];
  if (user && user.emailVerified) {
    promises.push(firebase.auth().currentUser.getIdTokenResult(true));
    promises.push(store.dispatch("auth/setLoginInfo", user));
    promises.push(store.dispatch("userInfo/bindUserInfoRef"));
  }

  // Note that at this stage, even though the promises of the binding with
  // Firestore will have return, we may not have any data. This may be caused
  // by poor internet connection for example.
  await Promise.all(promises);

  if (!app) {
    // We create the app, without mounting. We will only mount it
    // when the data from firestore are correctly bound. Not mounting
    // allow the spinner in "index.html" to not disappear until everything
    // is ready.
    app = new Vue({
      router,
      store,
      i18n,
      render: (h) => h(App),
      metaInfo: {
        meta: config.disableIndexing ? [{ name: "robots", content: "noindex, nofollow" }] : [],
      },
    });

    // What we want is to have a computed with all bindings inside, and then
    // watch this computed to really mount the app. We don't want this computed
    // and watcher in our main app as they are useless, so we add them in another
    // vue app.
    initialApp = new Vue({
      computed: {
        isBindingReady() {
          return (
            store.getters["platformSettings/isBindingReady"] &&
            store.getters["allProducts/isProductConfigsFirestoreBindingReady"]
          );
        },
      },
      watch: {
        isBindingReady: {
          handler(newVal) {
            if (newVal) {
              mountApp();
            }
          },
          // Don't forget that if everything went smoothly, normally we should immediately
          // be good, so we need to directly trigger the watcher.
          immediate: true,
        },
      },
    });
  }
});

axios.interceptors.request.use(
  async (config) => {
    if (
      config.headers &&
      (!config.headers["Authorization"] || config.headers["Authorization"] == "")
    ) {
      const token = await store.dispatch("auth/setToken");
      if (token) config.headers["Authorization"] = `FirebaseToken ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, async (error) => {
  // Sometimes, it can happen that the FirebaseToken is not valid anymore
  // (e.g. if the user didn't refresh for the time of life of the token), so
  // in this case the backend could return a response with a 4xx status code.
  // If this is the case, we want to retry one single time with a new token.
  const statusCodesToRetry = [401, 403];
  const statusCodesToSignout = [498];
  const originalRequest = error.config;
  // We need to transform back the headers from the AxiosHeader object to
  // a simple javascript object, otherwise if we retry this AxiosHeader is
  // badly interpreted by axios itself and transformed in [Object object].
  // https://github.com/axios/axios/issues/5089
  if (originalRequest && originalRequest.headers) {
    originalRequest.headers = JSON.parse(JSON.stringify(originalRequest.headers));
  }

  if (error.response && statusCodesToRetry.includes(error.response.status)) {
    if (!originalRequest._retry) {
      originalRequest._retry = true;
      // Retry but make sure to set the new token first.
      const token = await store.dispatch("auth/setToken");
      originalRequest.headers["Authorization"] = `FirebaseToken ${token}`;
      return axios(originalRequest);
    }
  } else if (error.response && statusCodesToSignout.includes(error.response.status)) {
    if (router.app.$route.name == "page-login") {
      return Promise.reject(error);
    }

    // If the token was revoked, sign out the user and remove the authorization header
    await store.dispatch("auth/signOut");
    return router.push({
      name: "page-login",
      params: { tokenInvalidated: true },
    });
  } else if (error.code && error.code == "ERR_NETWORK") {
    // In the case where cloud run wasn't able to handle the query because we
    // took more than 10 seconds to accept the query, we will retry as a it's
    // likely because a machine cold start, and if we retry it's likely hot.
    // We check for network error, because it's what axios get in this case,
    // as the response is sent back by cloud run which doesn't set the CORS
    // flag as our backend does.
    if (originalRequest._nRetries) {
      originalRequest._nRetries += 1;
    } else {
      originalRequest._nRetries = 1;
    }

    // We are at our 12 tentatives (counting original one), it means
    // we have been blocked for 2 minutes in the case of a 429
    // error, we stop.
    if (originalRequest._nRetries == 12) {
      return Promise.reject(error);
    }

    firebase.analytics().logEvent(SERVER_ERROR_RESPONSE_EVENT, {
      response_status_code: 429,
      response_status_text: `Retry ${originalRequest._nRetries}`,
      server_endpoint: originalRequest.url,
    });

    return axios(originalRequest);
  }

  // If not an error that we solved, we log one in analytics.
  let errorRequestUrl = error.config.url;
  if (error.config.params && Object.keys(error.config.params).length > 0) {
    errorRequestUrl += `?${Object.entries(error.config.params)
      .map((x) => x[0] + "=" + x[1])
      .join("&")}`;
  }

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx.
    firebase.analytics().logEvent(SERVER_ERROR_RESPONSE_EVENT, {
      response_status_code: error.response.status,
      response_status_text:
        error.response.statusText || error.message
          ? error.response.statusText + " | " + error.message
          : error,
      server_endpoint: errorRequestUrl,
    });
  } else if (error.request) {
    // The request was made but no response was received and
    // `error.request` is an instance of XMLHttpRequest in the browser.
    firebase.analytics().logEvent(SERVER_ERROR_REQUEST_EVENT, {
      response_status_code: error.code ?? error.name,
      response_status_text:
        error.code || error.name || error.message
          ? error.code + " | " + error.name + " | " + error.message
          : error,
      server_endpoint: errorRequestUrl,
    });
  } else {
    // Something happened in setting up the request that triggered an Error.
    firebase.analytics().logEvent(SERVER_ERROR_OTHER_EVENT, {
      response_status_text: error.message ?? error,
      server_endpoint: errorRequestUrl,
    });
  }

  return Promise.reject(error);
});
