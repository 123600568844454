import { cloneDeep } from "lodash";

import { Role } from "@/acl/roles";
import { GenericProductCards } from "@/assets/constants/analysisCards";
import { GLOBAL_EVENTS } from "@/assets/constants/eventsDates";
import { UpdateFrequency } from "@/assets/constants/frequencies";
import { STANDARD_PERF_STATS_WITHOUT_BENCHMARK } from "@/assets/constants/perfStats";
import { STANDARD_ZOOM_BUTTONS } from "@/assets/constants/zoomButtons";
import exports from "@/assets/scss/exports.module.scss";

const ORG_COLOR = exports["colors-primary"];
const INTERNAL_ALLOWED_ROLES = [Role.admin, Role.superAdmin];

// ******---------- AEQUITAS PATRIMONIUM ----------*****
const aequitasPatrimoniumRAcc = {
  productId: "aequitas-patrimonium-r-acc",
  storeModule: "aequitasPatrimoniumRAcc",
  productName: "AEQUITAS PATRIMONIUM R ACC",
  productShortName: "AEQUITAS PATRIMONIUM",
  productColor: ORG_COLOR,
  productShareClassRoot: "aequitas-patrimonium",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const aequitasPatrimoniumIAcc = cloneDeep(aequitasPatrimoniumRAcc);
aequitasPatrimoniumIAcc.productId = "aequitas-patrimonium-i-acc";
aequitasPatrimoniumIAcc.storeModule = "aequitasPatrimoniumIAcc";
aequitasPatrimoniumIAcc.productName = "AEQUITAS PATRIMONIUM I ACC";
aequitasPatrimoniumIAcc.isDefaultShareClass = false;

// ******---------- AEQUITAS EUROPEAN DIVIDEND VALUE FUND ----------*****
const aequitasEuropeanDividendValueFundCAcc = {
  productId: "aequitas-european-dividend-value-fund-c-acc",
  storeModule: "aequitasEuropeanDividendValueFundCAcc",
  productName: "AEQUITAS EUROPEAN DIVIDEND VALUE FUND C ACC",
  productShortName: "AEQUITAS EUROPEAN DIVIDEND VALUE FUND",
  productColor: ORG_COLOR,
  productShareClassRoot: "aequitas-european-dividend-value-fund",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const aequitasEuropeanDividendValueFundIAcc = cloneDeep(aequitasEuropeanDividendValueFundCAcc);
aequitasEuropeanDividendValueFundIAcc.productId = "aequitas-european-dividend-value-fund-i-acc";
aequitasEuropeanDividendValueFundIAcc.storeModule = "aequitasEuropeanDividendValueFundIAcc";
aequitasEuropeanDividendValueFundIAcc.productName = "AEQUITAS EUROPEAN DIVIDEND VALUE FUND I ACC";
aequitasEuropeanDividendValueFundIAcc.isDefaultShareClass = false;

const aequitasEuropeanDividendValueFundRAcc = cloneDeep(aequitasEuropeanDividendValueFundCAcc);
aequitasEuropeanDividendValueFundRAcc.productId = "aequitas-european-dividend-value-fund-r-acc";
aequitasEuropeanDividendValueFundRAcc.storeModule = "aequitasEuropeanDividendValueFundRAcc";
aequitasEuropeanDividendValueFundRAcc.productName = "AEQUITAS EUROPEAN DIVIDEND VALUE FUND R ACC";
aequitasEuropeanDividendValueFundRAcc.isDefaultShareClass = false;

const aequitasEuropeanDividendValueFundVAcc = cloneDeep(aequitasEuropeanDividendValueFundCAcc);
aequitasEuropeanDividendValueFundVAcc.productId = "aequitas-european-dividend-value-fund-v-acc";
aequitasEuropeanDividendValueFundVAcc.storeModule = "aequitasEuropeanDividendValueFundVAcc";
aequitasEuropeanDividendValueFundVAcc.productName = "AEQUITAS EUROPEAN DIVIDEND VALUE FUND V ACC";
aequitasEuropeanDividendValueFundVAcc.isDefaultShareClass = false;

// ******---------- AEQUITAS FIXED INCOME ----------*****
const aequitasFixedIncomeRAcc = {
  productId: "aequitas-fixed-income-r-acc",
  storeModule: "aequitasFixedIncomeRAcc",
  productName: "AEQUITAS FIXED INCOME R ACC",
  productShortName: "AEQUITAS FIXED INCOME",
  productColor: ORG_COLOR,
  productShareClassRoot: "aequitas-fixed-income",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const aequitasFixedIncomeIDis = cloneDeep(aequitasFixedIncomeRAcc);
aequitasFixedIncomeIDis.productId = "aequitas-fixed-income-i-dis";
aequitasFixedIncomeIDis.storeModule = "aequitasFixedIncomeIDis";
aequitasFixedIncomeIDis.productName = "AEQUITAS FIXED INCOME I DIS";
aequitasFixedIncomeIDis.isDefaultShareClass = false;

const aequitasFixedIncomeVDis = cloneDeep(aequitasFixedIncomeRAcc);
aequitasFixedIncomeVDis.productId = "aequitas-fixed-income-v-dis";
aequitasFixedIncomeVDis.storeModule = "aequitasFixedIncomeVDis";
aequitasFixedIncomeVDis.productName = "AEQUITAS FIXED INCOME V DIS";
aequitasFixedIncomeVDis.isDefaultShareClass = false;

// ******---------- AEQUITAS Exclusive Funds ----------*****
const aequitasExclusiveFundsClassI = {
  productId: "aequitas-exclusive-funds-class-i",
  storeModule: "aequitasExclusiveFundsClassI",
  productName: "AEQUITAS Exclusive Funds Class I",
  productShortName: "AEQUITAS Exclusive Funds",
  productColor: ORG_COLOR,
  productShareClassRoot: "aequitas-exclusive-funds",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.daily,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const aequitasExclusiveFundsClassR = cloneDeep(aequitasExclusiveFundsClassI);
aequitasExclusiveFundsClassR.productId = "aequitas-exclusive-funds-class-r";
aequitasExclusiveFundsClassR.storeModule = "aequitasExclusiveFundsClassR";
aequitasExclusiveFundsClassR.productName = "AEQUITAS Exclusive Funds Class R";
aequitasExclusiveFundsClassR.isDefaultShareClass = false;

// ******---------- GOLETA SELECT FUND ----------*****
const goletaSelectFundShareClassB = {
  productId: "goleta-select-fund-share-class-b",
  storeModule: "goletaSelectFundShareClassB",
  productName: "GOLETA SELECT FUND B",
  productShortName: "GOLETA SELECT FUND",
  productColor: ORG_COLOR,
  productShareClassRoot: "goleta-select-fund",
  isDefaultShareClass: true,
  live: {
    mode: "live",
    updateFrequency: UpdateFrequency.weekly,
    zoomButtons: STANDARD_ZOOM_BUTTONS,
    genericCardsToShow: [
      GenericProductCards.cumulativeReturns,
      GenericProductCards.performanceStats,
      GenericProductCards.drawdowns,
      GenericProductCards.volatility,
    ],
    perfStats: STANDARD_PERF_STATS_WITHOUT_BENCHMARK,
    events: GLOBAL_EVENTS,
  },
  factsheet: {
    allowedRoles: INTERNAL_ALLOWED_ROLES,
  },
};

const goletaSelectFundShareClassF = cloneDeep(goletaSelectFundShareClassB);
goletaSelectFundShareClassF.productId = "goleta-select-fund-share-class-f";
goletaSelectFundShareClassF.storeModule = "goletaSelectFundShareClassF";
goletaSelectFundShareClassF.productName = "GOLETA SELECT FUND F";
goletaSelectFundShareClassF.isDefaultShareClass = false;

export const FUNDS_AVENUE_PRODUCTS_DETAILS = [
  aequitasPatrimoniumRAcc,
  aequitasPatrimoniumIAcc,
  aequitasEuropeanDividendValueFundCAcc,
  aequitasEuropeanDividendValueFundIAcc,
  aequitasEuropeanDividendValueFundRAcc,
  aequitasEuropeanDividendValueFundVAcc,
  aequitasFixedIncomeRAcc,
  aequitasFixedIncomeIDis,
  aequitasFixedIncomeVDis,
  aequitasExclusiveFundsClassI,
  aequitasExclusiveFundsClassR,
  goletaSelectFundShareClassB,
  goletaSelectFundShareClassF,
];

export const FUNDS_AVENUE_PRODUCTS = FUNDS_AVENUE_PRODUCTS_DETAILS.map(
  (details) => details.storeModule
);
