import { Role } from "@/acl/roles";
import { FUNDS_AVENUE_PRODUCTS_DETAILS } from "@/assets/constants/products/funds-avenue";

export default {
  orgName: "Funds Avenue",
  logoPath: require("@/assets/images/logo/funds-avenue.svg"),
  navBarLogoStyle: "width: 100%; max-height: 100px;",
  allowedLoginMethods: ["Google"],
  products: FUNDS_AVENUE_PRODUCTS_DETAILS,
  possibleRoles: [Role.superAdmin, Role.admin],
  admin: true,
  availableLanguages: ["en"],
};
