import firebase from "firebase/compat/app";
import { intersectionWith, merge } from "lodash";
import Vue from "vue";
import { firestoreAction } from "vuexfire";

import { Role } from "@/acl/roles";
import {
  IP_INSTITUTIONAL_TYPE_ID,
  IP_PROFESSIONAL_TYPE_ID,
  IP_RETAIL_TYPE_ID,
} from "@/assets/constants/investorProfile";
import config from "@/config";
import { i18n } from "@/main.js";

// Keep a convenient mapping from a productId to its position in the javascript config file.
const PRODUCT_ID_TO_INDEX = config.products.reduce(
  (acc, productConfigJs, idx) => ((acc[productConfigJs.productId] = idx), acc),
  {}
);

// Function to order products using the order in which they are written in their config file.
function orderProducts(products) {
  // Note that we assume that each product in 'products' is in 'config.products'.
  const indexToProduct = new Map();
  products.forEach((product) => {
    const index = PRODUCT_ID_TO_INDEX[product.productId];
    indexToProduct.set(index, product);
  });

  const orderedMap = new Map([...indexToProduct].sort((a, b) => a[0] - b[0]));
  return Array.from(orderedMap.values());
}

const state = {
  shareClassRootToProductIdToShow: {},
  productConfigsFirestore: undefined,
};

const getters = {
  isProductConfigsFirestoreBindingReady: (state) => {
    return state.productConfigsFirestore !== undefined && state.productConfigsFirestore.length > 0;
  },
  getProductIdToShowFromShareClassRoot: (state) => (shareClassRoot) => {
    return state.shareClassRootToProductIdToShow[shareClassRoot];
  },
  getAllProducts: (state, getters) => {
    if (!getters.isProductConfigsFirestoreBindingReady) {
      return [];
    }

    const productIdToProductConfigFirestore = state.productConfigsFirestore.reduce(
      (acc, productConfigFirestore) => (
        (acc[productConfigFirestore.productId] = productConfigFirestore), acc
      ),
      {}
    );

    return orderProducts(
      config.products.map((productConfigJs) =>
        merge({}, productConfigJs, productIdToProductConfigFirestore[productConfigJs.productId])
      )
    );
  },
  getAllProductsAuthorized: (_, getters) => {
    // All products that are authorized for the user is the intersection of the ones authorized
    // based on the current role of the user and the ones authorized based on the investor
    // profile of the user.
    return orderProducts(
      intersectionWith(
        getters.getAllProductsNotHidden,
        getters.getAllProductsAuthorizedForRole,
        getters.getAllProductsAuthorizedForInvestorProfile,
        // Differentiate products by their id.
        (product1, product2) => product1.productId === product2.productId
      )
    );
  },
  getAllProductsAdmin: (_1, getters, _2, rootGetters) => {
    // Always return all products for general admins.
    if (rootGetters["userInfo/hasAnyRoles"]([Role.superAdmin, Role.admin])) {
      return getters.getAllProducts;
    }

    // Otherwise, see for which products we are admin through user-defined roles.
    return getters.getAllProducts.filter((product) =>
      rootGetters["userInfo/isAdminOf"](product.productId)
    );
  },
  getAllProductsNotHidden: (_1, getters, _2, rootGetters) => {
    // Always return all products for admins and employees.
    if (rootGetters["userInfo/hasAnyRoles"]([Role.superAdmin, Role.admin, Role.employee])) {
      return getters.getAllProducts;
    }

    // Returns all non-hidden products, and also those for which the user is an admin.
    return orderProducts(
      getters.getAllProducts.filter((product) => {
        return rootGetters["userInfo/isAdminOf"](product.productId) || !product.general?.hidden;
      })
    );
  },
  getAllProductsAuthorizedForRole: (_1, getters, _2, rootGetters) => {
    // Always return all products for general admins and employees.
    if (rootGetters["userInfo/hasAnyRoles"]([Role.superAdmin, Role.admin, Role.employee])) {
      return getters.getAllProducts;
    }

    return orderProducts(
      getters.getAllProducts.filter((product) => {
        const allowedRoles = product.allowedRoles ?? config.allowedRoles;
        return (
          rootGetters["userInfo/isAdminOf"](product.productId) ||
          !allowedRoles ||
          rootGetters["userInfo/hasAnyRoles"](allowedRoles)
        );
      })
    );
  },
  getAllProductsAuthorizedForInvestorProfile: (_1, getters, _2, rootGetters) => {
    // Always return all products for general admins and employees.
    if (rootGetters["userInfo/hasAnyRoles"]([Role.superAdmin, Role.admin, Role.employee])) {
      return getters.getAllProducts;
    }

    // Check if we shouldn't use the investor profile for this platform.
    if (
      !rootGetters["platformSettings/getCompliance"](i18n.locale)?.[
        "investorProfileChecksActivated"
      ]
    ) {
      return getters.getAllProducts;
    }

    // Get the investor profile (type and country) of the user.
    const userInfo = rootGetters["userInfo/getUserInfo"];
    const investorProfileType =
      (userInfo && userInfo.investorProfile) || rootGetters["userInfo/getIpTypeBrowserStored"];
    const investorProfileCountry =
      (userInfo && userInfo.country) || rootGetters["userInfo/getIpCountryBrowserStored"];

    // Return an empty list if we still don't have the investor profile type or country
    // (this can happen when getting the list of product from the FullPage view because
    // they are on the page to enter their investor profile).
    if (!investorProfileType || !investorProfileCountry) {
      return [];
    }

    return orderProducts(
      getters.getAllProducts.filter((product) => {
        // Check first if the user is an admin of this product, and give access if that's the case.
        if (rootGetters["userInfo/isAdminOf"](product.productId)) {
          return true;
        }

        // Check if the country is in the corresponding list of authorized countries.
        if (investorProfileType === IP_RETAIL_TYPE_ID) {
          const countriesNotSet = product.accessRestrictions?.retailCountries == undefined;
          return (
            countriesNotSet ||
            product.accessRestrictions?.retailCountries?.includes(investorProfileCountry)
          );
        }
        if (investorProfileType === IP_PROFESSIONAL_TYPE_ID) {
          const countriesNotSet = product.accessRestrictions?.professionalCountries == undefined;
          return (
            countriesNotSet ||
            product.accessRestrictions?.professionalCountries?.includes(investorProfileCountry)
          );
        }
        if (investorProfileType === IP_INSTITUTIONAL_TYPE_ID) {
          const countriesNotSet = product.accessRestrictions?.institutionalCountries == undefined;
          return (
            countriesNotSet ||
            product.accessRestrictions?.institutionalCountries?.includes(investorProfileCountry)
          );
        }

        throw new Error(`Unknown investor profile type: ${investorProfileType}`);
      })
    );
  },
  getAllRootProducts: (_, getters) => {
    return orderProducts(
      getters.getAllProducts.filter((product) => {
        const hasNoShareClass = product.productShareClassRoot === undefined;
        const isRoot = product.isDefaultShareClass;
        return hasNoShareClass || isRoot;
      })
    );
  },
  getAllShareClassesAuthorized: (_, getters) => (productId) => {
    const product = getters.getAllProducts.find((product) => product.productId === productId);
    if (!product) {
      throw new Error(`Cannot find the corresponding id ${productId}.`);
    }

    return orderProducts(
      getters.getAllProductsAuthorized.filter((authProduct) => {
        const hasNoShareClassAndIsProduct =
          authProduct.productShareClassRoot === undefined &&
          authProduct.productName === product.productName;
        const isShareClassOfProduct =
          authProduct.productShareClassRoot !== undefined &&
          authProduct.productShareClassRoot === product.productShareClassRoot;
        return hasNoShareClassAndIsProduct || isShareClassOfProduct;
      })
    );
  },
  getAllShareClassesAdmin: (_, getters) => (productId) => {
    const product = getters.getAllProducts.find((product) => product.productId === productId);
    if (!product) {
      throw new Error(`Cannot find the corresponding id ${productId}.`);
    }

    return orderProducts(
      getters.getAllProductsAdmin.filter((authProduct) => {
        const hasNoShareClassAndIsProduct =
          authProduct.productShareClassRoot === undefined &&
          authProduct.productName === product.productName;
        const isShareClassOfProduct =
          authProduct.productShareClassRoot !== undefined &&
          authProduct.productShareClassRoot === product.productShareClassRoot;
        return hasNoShareClassAndIsProduct || isShareClassOfProduct;
      })
    );
  },
  getAllSelectedShareClassesAuthorized: (_, getters) => {
    // We are going to look for each product authorized which share class
    // we need to show. Note the first "map" might lead to multiple products
    // to be mapped to the same share class we want to show. More in the
    // comments below.
    const selectedShareClasses = getters.getAllProductsAuthorized
      .map((product) => {
        // If the product has no share classes, simply return the product as the
        // "selected" share class.
        if (product.productShareClassRoot === undefined) {
          return product;
        }

        // First we get the saved productId that we have set in local storage for the share class
        // root.
        const selectedProductId = getters.getProductIdToShowFromShareClassRoot(
          product.productShareClassRoot
        );
        // We get all the share classes authorized for the current product.
        const authorizedShareClasses = getters.getAllShareClassesAuthorized(product.productId);
        // If we never set the product we want to show for a share class root,
        // we simply return the first we are authorized to see.
        if (selectedProductId === null) {
          return authorizedShareClasses[0];
        }

        // If we have a selectedProductId, we need to check that we
        // still have the access for it, as it might have been modified.
        if (
          authorizedShareClasses.find(
            (authorizedShareClass) => authorizedShareClass.productId == selectedProductId
          ) === undefined
        ) {
          return authorizedShareClasses[0];
        }

        // If we reach this point, we need to return the product object
        // for the share class we want to see.
        return authorizedShareClasses.find(
          (authorizedShareClass) => authorizedShareClass.productId == selectedProductId
        );
      })
      // At this point, if more than one share class was authorized to be
      // seen, then we will have multiple time one share class mapped to
      // another, e.g. if we are able to see both share class of share class
      // root "equity-fund", then in the resulting array of the map, we will
      // have either two times "equity-fund-usd" or "equity-fund-eur".
      // Note that for sure we will two times the *same* share class selected.
      // Therefore before returning the final array, we do a "distinct" on
      // the array productIds.
      .filter(
        (shareClass, index, array) =>
          array.map((shareClass) => shareClass.productId).indexOf(shareClass.productId) === index
      );

    return orderProducts(selectedShareClasses);
  },
};

const mutations = {
  UPDATE_SHARE_CLASS_ROOT_TO_PRODUCT_ID_TO_SHOW(state, { shareClassRoot, productId }) {
    Vue.set(state.shareClassRootToProductIdToShow, shareClassRoot, productId);
  },
};

const actions = {
  bindProductsConfigFirestore: firestoreAction(({ bindFirestoreRef }) => {
    return bindFirestoreRef(
      "productConfigsFirestore",
      firebase.firestore().collection("products"),
      {
        serialize: (doc) => {
          // We need the productId, which is the key of the document, inside the data.
          return { productId: doc.id, ...doc.data() };
        },
      }
    );
  }),
  initShareClassRootToProductIdToShow({ commit }) {
    config.products
      .filter((productConfig) => productConfig.productShareClassRoot)
      .map((productConfig) => productConfig.productShareClassRoot)
      // Equivalent of .distinct()
      .filter((shareClassRoot, index, array) => array.indexOf(shareClassRoot) === index)
      .forEach((shareClassRoot) =>
        commit("UPDATE_SHARE_CLASS_ROOT_TO_PRODUCT_ID_TO_SHOW", {
          shareClassRoot,
          productId: localStorage.getItem(shareClassRoot),
        })
      );
  },
  setProductIdToShowForShareClassRoot({ commit }, { shareClassRoot, productId }) {
    if (localStorage.getItem(shareClassRoot) != productId) {
      localStorage.setItem(shareClassRoot, productId);
      commit("UPDATE_SHARE_CLASS_ROOT_TO_PRODUCT_ID_TO_SHOW", {
        shareClassRoot,
        productId,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
